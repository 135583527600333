import { Popper, styled } from '@mui/material';

const PopperStyled = styled(Popper)(({ theme }) => ({
  marginTop: theme.spacing(1),
  borderRadius: '12px',
  backgroundColor: theme.palette.white.main,
  overflow: 'hidden',
  boxShadow:
    '0px 5px 10px 0px rgba(30, 34, 40, 0.1), 0px 0px 1px 0px rgba(30, 34, 40, 0.08)',
  zIndex: 1,
}));

export default PopperStyled;
