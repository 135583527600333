import { Grid } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';

import { LinkIcon, TableCellStatusLine } from './styles';
import { renderCell } from 'components/common/Table/CommonTable/helpers';
import { TextWithTooltip } from 'components/common/TextWithTooltip';
import OperationalStatusChip from 'components/pages/protected/WindTurbines/components/OperationalStatusChip';
import { WindTurbineStatus } from 'components/pages/protected/WindTurbines/WindTurbinePage/components/WindTurbineStatus';
import { getWindTurbinePageLink } from 'components/pages/protected/WindTurbines/WindTurbinePage/helpers';
import {
  WindTurbineDto,
  WindTurbineDtoOperationalStatusEnum,
} from 'openapi-api/admin-service';
import { TranslationFunction } from 'types/general';

type GenerateColumnsPropsType = {
  t: TranslationFunction;
};

export const generateColumns = ({
  t,
}: GenerateColumnsPropsType): GridColDef<WindTurbineDto>[] => [
  {
    field: 'name',
    headerName: t('components.windTurbinesTable.columns.name'),
    flex: 1,
    minWidth: 200,
    sortable: true,
    disableColumnMenu: true,
    renderCell: ({
      value,
      row: { id, status, operationalStatus, publicId },
      api,
    }) => {
      return (
        <Grid
          gap={{ mobile: 1.5, tablet: 2 }}
          display="flex"
          flexDirection="column"
        >
          <TextWithTooltip
            textProps={{ variant: 'h5', color: 'black.900' }}
            tooltipProps={{ placement: 'top' }}
            text={value || publicId}
          />
          <OperationalStatusChip
            operationalStatus={
              operationalStatus ||
              WindTurbineDtoOperationalStatusEnum.DEACTIVATED
            }
          />
          <TableCellStatusLine
            windTurbineStatus={status}
            isFirstRow={
              api.getRowIndexRelativeToVisibleRows(id as number) === 0
            }
          />
        </Grid>
      );
    },
  },
  {
    field: 'status',
    headerName: t('components.windTurbinesTable.columns.status'),
    flex: 1,
    minWidth: 200,
    disableColumnMenu: true,
    sortable: false,
    renderCell: ({ value, row: { errorType } }) => (
      <WindTurbineStatus status={value} errorType={errorType} height={25} />
    ),
  },
  {
    field: 'windSpeed',
    headerName: t('components.windTurbinesTable.columns.windSpeed'),
    flex: 1,
    minWidth: 140,
    disableColumnMenu: true,
    renderCell: ({ value }) =>
      renderCell(`${(value || 0).toFixed(1)} m/s`, 'bodyM'),
  },
  {
    field: 'activePower',
    headerName: t('components.windTurbinesTable.columns.activePower'),
    flex: 1,
    minWidth: 140,
    disableColumnMenu: true,
    renderCell: ({ value }) =>
      renderCell(`${(value || 0).toFixed(3)} W`, 'bodyM'),
  },
  {
    field: 'linkToDetailedPage',
    headerName: '',
    minWidth: 72,
    sortable: false,
    disableColumnMenu: true,
    renderCell: ({ row: { publicId, location } }) => (
      <Link
        to={
          getWindTurbinePageLink({
            assetLocationId: location?.id,
            assetPublicId: publicId as string,
          }).root
        }
        target="_blank"
      >
        <LinkIcon />
      </Link>
    ),
  },
];
