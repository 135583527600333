import { FC } from 'react';

import WindTurbineStatusesContent from './WindTurbineStatusesContent';
import FullSizeLoader from 'components/common/FullSizeLoader';
import { NoData } from 'components/common/NoData';
import useWindTurbine from 'contexts/WindTurbine';
import { WindTurbineDtoStatusEnum } from 'openapi-api/admin-service';

export const WindTurbineStatuses: FC = () => {
  const { windTurbine, isLoading } = useWindTurbine();

  if (isLoading) {
    return <FullSizeLoader />;
  }

  if (
    windTurbine?.status === WindTurbineDtoStatusEnum.CREATED ||
    windTurbine?.status === WindTurbineDtoStatusEnum.ONBOARDING
  ) {
    return <NoData />;
  }

  return <WindTurbineStatusesContent />;
};
