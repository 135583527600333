import { SxProps } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Dash } from './styles';
import DateRangeWrapper from './styles/DateRangeWrapper';
import DatePicker from '../DatePicker';

export type ValueProps = 'fromDate' | 'toDate';
interface DateRangePickerProps {
  onChange?: (field: ValueProps, value: Dayjs | null) => void;
  error?: boolean;
  withoutDash?: boolean;
  fromDate?: string | null;
  toDate?: string | null;
  className?: string;
  sx?: SxProps;
}

const DateRangePicker: FC<DateRangePickerProps> = ({
  onChange,
  fromDate = null,
  toDate = null,
  sx,
  className,
  withoutDash = false,
}) => {
  const { t } = useTranslation();

  const onValueChange = useCallback(
    (inputName: ValueProps, newValue: Dayjs | null) => {
      onChange?.(inputName, newValue?.isValid() ? newValue : null);
    },
    [onChange],
  );

  const fromDateValue = !fromDate ? null : dayjs(fromDate);
  const toDateValue = !toDate ? null : dayjs(toDate);

  return (
    <DateRangeWrapper sx={sx} className={className}>
      <DatePicker
        label={t`components.datePicker.from`}
        value={fromDateValue}
        onChange={(dateValue) => onValueChange('fromDate', dateValue)}
        maxDate={toDateValue || dayjs()}
      />
      {!withoutDash && <Dash variant="bodyM">{'-'}</Dash>}
      <DatePicker
        label={t`components.datePicker.to`}
        value={toDateValue}
        onChange={(dateValue) => onValueChange('toDate', dateValue)}
        minDate={fromDateValue || undefined}
        maxDate={dayjs()}
      />
    </DateRangeWrapper>
  );
};

export default DateRangePicker;
