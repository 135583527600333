import ClearIcon from '@mui/icons-material/Clear';
import { styled } from '@mui/material/styles';

const SearchFieldClearIcon = styled(ClearIcon)(({ theme }) => ({
  margin: theme.spacing(0.5, 1),
  height: '24px',
  width: '24px',
  color: theme.palette.green[50],
  cursor: 'pointer',
  position: 'absolute',
  right: 0,
  top: '8px',
}));

export default SearchFieldClearIcon;
