import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import NotificationsListWrapper from './styles/NotificationsListWrapper';
import NotificationMessage from 'components/common/Notifications/NotificationMessage';
import { NotificationDto } from 'openapi-api/admin-service';

export interface Props {
  notifications: NotificationDto[];
  readNotification: (notification: NotificationDto) => void;
}
export const NotificationsList: FC<Props> = ({
  notifications,
  readNotification,
}) => {
  const { type } = useParams();

  return (
    <>
      <NotificationsListWrapper>
        {notifications.map((notification) => (
          <NotificationMessage
            key={`${notification?.id}-${type}`}
            notification={notification}
            onClick={readNotification}
            extended={true}
          />
        ))}
      </NotificationsListWrapper>
    </>
  );
};
