import { Box } from '@mui/material';
import { FC, IframeHTMLAttributes } from 'react';

import FullSizeLoader from '../FullSizeLoader';
import { NoData } from '../NoData';

interface Props extends IframeHTMLAttributes<HTMLIFrameElement> {
  isLoading?: boolean;
}
export const IframeWrapper: FC<Props> = ({ isLoading, ...props }) => {
  if (isLoading) {
    return (
      <Box
        width="100%"
        height={props.height}
        sx={{ position: 'relative', zIndex: 10 }}
      >
        <FullSizeLoader />
      </Box>
    );
  }

  if (!props.src) {
    return <NoData sx={{ height: props.height }} />;
  }

  return <iframe title={props.title} {...props} />;
};
