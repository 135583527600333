import { styled } from '@mui/material';

import { TabsSection } from 'components/common/TabsSection/TabsSection';

export const TabsSectionStyled = styled(TabsSection)(({ theme }) => ({
  gap: theme.spacing(1.5),

  '& .MuiTabs-flexContainer': {
    gap: theme.spacing(5),
    padding: 0,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },

  '& .MuiTab-root': {
    flexGrow: 0,
    minWidth: 'fit-content',
  },
}));
