import { FC } from 'react';

type Props = {
  className?: string;
};
const CheckMark: FC<Props> = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M6 12L10.2426 16.2427L18.7279 7.75739"
      stroke="#D4D7DC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CheckMark;
