import { styled } from '@mui/material';

import CheckMark from 'components/icons/CheckMark';

const CheckMarkIcon = styled(CheckMark)(({ theme }) => ({
  marginLeft: 'auto',
  '& path': {
    stroke: theme.palette.green[600],
  },
}));

export default CheckMarkIcon;
