import { styled } from '@mui/material';

import Tabs from 'components/common/Tabs';

const TabsStyled = styled(Tabs)(({ theme }) => ({
  marginTop: theme.spacing(6.5),
  boxShadow: `inset 0 -1px 0 ${theme.palette.grey[100]}`,

  [theme.breakpoints.down('desktop')]: {
    marginTop: theme.spacing(5),
    padding: theme.spacing(0, 2.5),
  },

  [theme.breakpoints.down('tablet')]: {
    margin: theme.spacing(5, -2.5, 0),
    padding: theme.spacing(0, 1.5),
  },
}));

export default TabsStyled;
