import routePaths from './routePaths';
import { TranslationFunction } from '../types/general';
import { TabType } from 'components/common/Tabs/types';

export const generateTabs = (t: TranslationFunction): TabType[] => [
  { label: t`tabs.parks`, value: routePaths.parks.root },
  { label: t`tabs.reports`, value: routePaths.reports },
  { label: t`tabs.solarPanels`, value: '/solar-panels', disabled: true },
  {
    label: t`tabs.more`,
    value: 'more',
    submenu: [
      {
        label: t`notifications.title`,
        link: routePaths.notifications('all'),
        pattern: new RegExp(`^${routePaths.notifications('')}`),
      },
    ],
  },
];
