import React, { FC, PropsWithChildren, useCallback, useMemo } from 'react';

import { UserContext } from './Context';
import useApi from 'contexts/api';
import useAuth from 'contexts/auth';
import { useAsyncResource } from 'utils/hooks/useAsyncResource';

const UserProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { userDetailsControllerApi } = useApi();
  const { tokenApplied } = useAuth();

  const loadUser = useCallback(async () => {
    if (tokenApplied) {
      return (await userDetailsControllerApi.getUserDetail()).data;
    }
  }, [userDetailsControllerApi, tokenApplied]);

  const { resource: user } = useAsyncResource({
    fetchResource: loadUser,
    defaultIsLoading: true,
    disableGlobalLoader: true,
  });

  const contextValue = useMemo(() => user || {}, [user]);

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

export default UserProvider;
