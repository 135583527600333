import { FC, ReactNode } from 'react';

interface ConditionalWrapperProps {
  condition: boolean;
  Wrapper: FC<ReactNode>;
  children: ReactNode;
}
export const ConditionalWrapper: FC<ConditionalWrapperProps> = ({
  condition,
  Wrapper,
  children,
}) => {
  if (!condition) {
    return <>{children}</>;
  }

  return Wrapper(children);
};
