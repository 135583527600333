import { Switch } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

const StyledSwitch = styled(Switch)(({ theme }) => ({
  width: '60px',
  height: '40px',
  padding: theme.spacing(0.5),
  '& .MuiSwitch-switchBase': {
    padding: theme.spacing(1.5),
    color: alpha(theme.palette.grey[900], 0.3), // grey-30
    transition: theme.transitions.create(['padding', 'transform', 'color'], {
      duration: 200,
    }),
    '&:hover': {
      backgroundColor: alpha(theme.palette.grey[900], 0.05), // grey-5
    },
    '&.Mui-checked': {
      padding: theme.spacing(1),
      transform: 'translateX(20px)',
      color: theme.palette.white.main,
      '&:hover': {
        backgroundColor: alpha(theme.palette.grey[900], 0.05), // grey-5
      },
      '&.Mui-disabled': {
        color: theme.palette.white.main,
      },
      '& .MuiSwitch-thumb': {
        width: '24px',
        height: '24px',
      },
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.green[500],
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.6,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: theme.palette.green[500],
      border: `6px solid ${theme.palette.white.main}`,
    },
    '&:not(.Mui-checked)': {
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: alpha(theme.palette.grey[900], 0.3), // grey-30
        opacity: 0.6,
      },
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.6,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: '16px',
    height: '16px',
    transition: theme.transitions.create(['width', 'height'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: '16px',
    backgroundColor: alpha(theme.palette.grey[900], 0.05), // grey-5
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 200,
    }),
  },
}));

export default StyledSwitch;
