import { styled } from '@mui/material';

export const StatusFilterItem = styled('div', {
  shouldForwardProp: (propName) =>
    propName !== 'isActive' && propName !== 'hideDivider',
})<{ isActive?: boolean; hideDivider?: boolean }>(
  ({ theme, isActive, hideDivider }) => ({
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
    padding: theme.spacing(4),
    backgroundColor: isActive
      ? theme.palette.green['100']
      : theme.palette.green['300'],
    cursor: 'pointer',

    '& .MuiTypography-root': {
      color: theme.palette.black[500],
    },

    '&:not(:first-of-type):after': {
      content: '""',
      position: 'absolute',
      left: '0px',
      height: '55px',
      width: '1px',
      backgroundColor:
        isActive || hideDivider ? 'transparent' : theme.palette.green['600'],
    },

    [theme.breakpoints.down('desktop')]: {
      gap: theme.spacing(1),
      padding: theme.spacing(2.5, 2),

      '&:not(:first-of-type):after': {
        top: 0,
        height: '100%',
      },
    },
  }),
);
