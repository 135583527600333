import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  FC,
  PropsWithChildren,
} from 'react';
import { useTranslation } from 'react-i18next';

import AuthContext from './Context';
import ButtonsWrapper from './styles/ButtonsWrapper';
import ContentWrapper from './styles/ContentWrapper';
import TimeWrapper from './styles/TimeWrapper';
import useInterceptors from './useInterceptors';
import useLogoutTimer from './useLogoutTimer';
import CommonButton from '../../components/common/CommonButton';
import CommonDialog from '../../components/common/CommonDialog';
import toHMS from '../../utils/functions/toHMS';
import useLocalStorage from '../../utils/hooks/useLocalStorage';
import useApi from '../api';
import { JwtAuthentication } from 'openapi-api/admin-service';

export const DialogId = 'session-expire-dialog';

const AuthProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { jwtAuthControllerApi } = useApi();
  const [accessData, setAccessData] =
    useLocalStorage<JwtAuthentication>('access-data');

  const [tokenApplied, setTokenApplied] = useState(false);
  const { t } = useTranslation();

  const { showConfirmation, secondsToExpire, refreshTimer } = useLogoutTimer(
    tokenApplied,
    DialogId,
  );

  const onLogin = useCallback(
    async (username: string, password: string) => {
      const { data } = await jwtAuthControllerApi.signIn({
        username,
        signature: password,
        portalOrigin: 'user-portal',
      });
      setAccessData(data);
    },
    [setAccessData, jwtAuthControllerApi],
  );

  const removeAuthData = useCallback(() => {
    setAccessData(null);
  }, [setAccessData]);

  const onLogout = useCallback(async () => {
    try {
      await jwtAuthControllerApi.logout();
    } catch {
    } finally {
      removeAuthData();
      refreshTimer();
    }
  }, [removeAuthData, refreshTimer, jwtAuthControllerApi]);

  useInterceptors(removeAuthData, accessData, setAccessData);

  useEffect(() => {
    if (accessData?.accessToken) {
      axios.defaults.headers.common.Authorization =
        'Bearer ' + accessData.accessToken;
      setTokenApplied(true);
    }

    return () => {
      delete axios.defaults.headers.common.Authorization;
      setTokenApplied(false);
    };
  }, [accessData?.accessToken]);

  const contextValue = useMemo(
    () => ({
      tokenApplied,
      dataExists: !!accessData?.accessToken,
      onLogin,
      onLogout,
      accessData,
    }),
    [tokenApplied, accessData, onLogin, onLogout],
  );

  useEffect(() => {
    if (secondsToExpire <= 0) {
      onLogout();
    }
  }, [onLogout, secondsToExpire]);

  return (
    <AuthContext.Provider value={contextValue}>
      <CommonDialog
        yPadding={6}
        width={540}
        open={showConfirmation}
        onClose={refreshTimer}
        id={DialogId}
        testId={DialogId}
      >
        <ContentWrapper>
          <Grid alignItems="center" container justifyContent="center">
            <Grid gap={1} container justifyContent="center">
              <Typography variant="h1">
                <TimeWrapper>{toHMS(secondsToExpire).minutes}</TimeWrapper>
              </Typography>
              <Typography variant="h1" color="green.600">
                {':'}
              </Typography>
              <Typography variant="h1">
                <TimeWrapper>{toHMS(secondsToExpire).seconds}</TimeWrapper>
              </Typography>
            </Grid>
            <Typography align="center" variant="h2" sx={{ mt: 5 }}>
              {t`autoLogoutModal.title`}
            </Typography>
            <Typography align="center" variant="bodyM" sx={{ mt: 2.5 }}>
              {t`autoLogoutModal.subTitle`}
            </Typography>
          </Grid>
          <ButtonsWrapper>
            <CommonButton variant="contained" onClick={refreshTimer}>
              {t`autoLogoutModal.continue`}
            </CommonButton>
            <CommonButton
              variant="outlined"
              onClick={onLogout}
              sx={{ width: 'fit-content' }}
            >
              {t`logOut`}
            </CommonButton>
          </ButtonsWrapper>
        </ContentWrapper>
      </CommonDialog>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
