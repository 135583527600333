import { styled } from '@mui/material/styles';

const ContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(6.5),
  height: '100%',
  [theme.breakpoints.down('desktop')]: {
    padding: theme.spacing(5, 0),
    justifyContent: 'center',
  },
  [theme.breakpoints.down('tablet')]: {
    padding: theme.spacing(14, 0, 3, 0),
    justifyContent: 'space-between',
  },
}));

export default ContentWrapper;
