import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

const TextFieldStyled = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    borderRadius: '12px',
    overflow: 'hidden',
    backgroundColor: theme.palette.grey[50],

    '&:hover, &.Mui-focused': {
      backgroundColor: theme.palette.grey[50],
    },

    '& > input:-webkit-autofill': {
      WebkitBoxShadow: `0 0 0 100px ${theme.palette.grey[50]} inset`,
    },
  },
}));

export default TextFieldStyled;
