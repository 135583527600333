import { FC } from 'react';

import { CommonSvgProps } from 'types/general';

export const ArrowLeft: FC<CommonSvgProps> = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M7 11C6.44771 11 6 11.4477 6 12C6 12.5523 6.44771 13 7 13L7 11ZM17 11L7 11L7 13L17 13L17 11Z"
      fill="#21252C"
    />
    <path
      d="M11 16L7 12L11 8"
      stroke="#21252C"
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);
