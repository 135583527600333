import * as yup from 'yup';

import { withoutSpacesAtStartEnd } from '../../../../constants/regex';
import { TranslationFunction } from '../../../../types/general';

export const lengthValidation = /^.{8,32}$/;
export const atLeastOneNumber = /\d/;
export const atLeastOneLowercase = /[a-z]/;
export const atLeastOneUppercase = /[A-Z]/;
export const atLeastOneSpecial = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

const validationSchema = (t: TranslationFunction) =>
  yup.object({
    newPassword: yup
      .string()
      .required(t`form.errors.fieldRequired`)
      .matches(lengthValidation, t`form.errors.noSecurityCriteria`)
      .matches(atLeastOneNumber, t`form.errors.noSecurityCriteria`)
      .matches(atLeastOneLowercase, t`form.errors.noSecurityCriteria`)
      .matches(atLeastOneUppercase, t`form.errors.noSecurityCriteria`)
      .matches(atLeastOneSpecial, t`form.errors.noSecurityCriteria`)
      .matches(withoutSpacesAtStartEnd, t`form.errors.spacesAreNotAllowed`),
    newPasswordRepeat: yup
      .string()
      .required(t`form.errors.fieldRequired`)
      .oneOf([yup.ref('newPassword')], t`form.errors.passwordMustMatch`),
    currentPassword: yup.string().required(t`form.errors.fieldRequired`),
  });

export default validationSchema;
