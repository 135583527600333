import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import { NotificationCounterItem, NotificationCountersWrapper } from './styles';
import { NotificationsPopup } from '../NotificationsPopup';
import NotificationsTooltip from '../NotificationsTooltip';
import useNotificationsContext from 'contexts/notifications';

export const NotificationCountersBlock: FC = () => {
  const timer = React.useRef<NodeJS.Timeout | null>(null);
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const {
    isNotificationsPopupOpened,
    openNotificationsPopup,
    closeNotificationsPopup,
    unreadNotificationsCount,
    lastNewNotification,
    markAsNotNew,
  } = useNotificationsContext();

  const openNotificationsBlockSpecificTab = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>, tab: number) => {
      e.stopPropagation();

      if (isNotificationsPopupOpened) {
        closeNotificationsPopup();
        return;
      }

      openNotificationsPopup(tab);
      setTooltipOpen(false);
    },
    [
      closeNotificationsPopup,
      isNotificationsPopupOpened,
      openNotificationsPopup,
    ],
  );

  const onTooltipClick = useCallback(() => {
    openNotificationsPopup();
    setTooltipOpen(false);
  }, [openNotificationsPopup]);

  const onTooltipCloseClick = useCallback(() => {
    setTooltipOpen(false);
    markAsNotNew();
  }, [markAsNotNew]);

  useEffect(() => {
    if (lastNewNotification?.id && !isNotificationsPopupOpened) {
      if (timer.current) {
        clearTimeout(timer.current);
        timer.current = null;
      }
      setTooltipOpen(true);
      timer.current = setTimeout(() => {
        setTooltipOpen(false);
      }, 7000);

      return () => {
        if (timer.current) {
          clearTimeout(timer.current);
          timer.current = null;
        }
      };
    }
  }, [lastNewNotification, isNotificationsPopupOpened]);

  const wrapperRef = useRef(null);

  return (
    <Grid position="relative" ref={wrapperRef}>
      <NotificationsTooltip
        open={isTooltipOpen}
        errorsCount={unreadNotificationsCount.ERROR}
        remindersCount={unreadNotificationsCount.REMINDER}
        onClick={onTooltipClick}
        onClose={onTooltipCloseClick}
        data-testid="notifications-tooltip"
      >
        <NotificationCountersWrapper
          onClick={(e) => openNotificationsBlockSpecificTab(e, 0)}
          data-testid="notifications-counter"
        >
          <NotificationCounterItem
            onClick={(e) => openNotificationsBlockSpecificTab(e, 0)}
          >
            <img src="/images/notifications-errors-counter.svg" alt="errors" />
            <Typography variant="subheading">
              {unreadNotificationsCount.ERROR}
            </Typography>
          </NotificationCounterItem>
          <NotificationCounterItem
            onClick={(e) => openNotificationsBlockSpecificTab(e, 1)}
          >
            <img
              src="/images/notifications-warnings-counter.svg"
              alt="warnings"
            />
            <Typography variant="subheading">
              {unreadNotificationsCount.WARNING}
            </Typography>
          </NotificationCounterItem>
          <NotificationCounterItem
            onClick={(e) => openNotificationsBlockSpecificTab(e, 2)}
          >
            <img
              src="/images/notifications-reminders-counter.svg"
              alt="reminders"
            />
            <Typography variant="subheading">
              {unreadNotificationsCount.REMINDER}
            </Typography>
          </NotificationCounterItem>
        </NotificationCountersWrapper>
      </NotificationsTooltip>
      <NotificationsPopup anchorEl={wrapperRef.current} />
    </Grid>
  );
};
