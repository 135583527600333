import { Typography } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ActionsItem,
  ActionsList,
  CloseButton,
  ContentHead,
  ContentWrapper,
  DialogStyled,
} from './styles';
import Close from 'components/icons/Close';
import useWindTurbine from 'contexts/WindTurbine';

export type MobileMenuItem = { text: string; onClick: () => void };

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onEditAssetDetailsClick?: () => void;
}

export const MobileMenuActions: FC<Props> = ({
  isOpen,
  onClose,
  onEditAssetDetailsClick,
}) => {
  const { t } = useTranslation();
  const { windTurbine } = useWindTurbine();

  const items: MobileMenuItem[] = useMemo(() => {
    return [
      {
        text: t`pages.turbine.editAssetDetails`,
        onClick: onEditAssetDetailsClick,
      },
    ].filter((item): item is MobileMenuItem => !!item);
  }, [t, onEditAssetDetailsClick]);

  const onItemClick = useCallback(
    (action: (() => void) | undefined) => {
      onClose();
      action?.();
    },
    [onClose],
  );

  return (
    <DialogStyled open={isOpen} onClose={onClose}>
      <CloseButton onClick={onClose}>
        <Close />
      </CloseButton>
      <ContentWrapper>
        <ContentHead>
          <Typography variant="h5">
            {windTurbine?.name || windTurbine?.publicId}
          </Typography>
        </ContentHead>
        <ActionsList>
          {items.map((item) => (
            <ActionsItem
              key={`mobile-menu-action-${item.text}`}
              onClick={() => onItemClick(item.onClick)}
            >
              <Typography variant="bodyL">{item.text}</Typography>
            </ActionsItem>
          ))}
        </ActionsList>
      </ContentWrapper>
    </DialogStyled>
  );
};
