import { Box, useMediaQuery, useTheme } from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { generateColumns } from './columns';
import {
  clickDisabledFields,
  fieldsToSend,
  sorting,
  turbinesFilterValues,
} from './constants';
import { TableAboveWrapper, Wrapper } from './styles';
import { WindTurbinesTableProps } from './types';
import { AppliedFilters } from 'components/common/AppliedFilters';
import {
  CommonTable,
  FilterMenu,
  Search,
  TablePagination,
} from 'components/common/Table/CommonTable';
import { getWindTurbinePageLink } from 'components/pages/protected/WindTurbines/WindTurbinePage/helpers';
import useApi from 'contexts/api';
import {
  WindTurbineControllerApiAllWindTurbinesRequest,
  WindTurbineDtoOperationalStatusEnum,
  WindTurbineDtoStatusEnum,
} from 'openapi-api/admin-service';
import { FilterItem } from 'types/general';
import { formatObjFromQuery } from 'utils/functions/formatQuery';
import { getRowsPerPage } from 'utils/functions/getRowsPerPage';
import { sortingMapping } from 'utils/functions/sortingMapping';
import { useAsyncResourceWithPulling } from 'utils/hooks/useAsyncResourceWithPulling';
import useDataGrid from 'utils/hooks/useDataGrid';

export const WindTurbinesTable: FC<WindTurbinesTableProps> = ({
  locationId,
  className,
  sx,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { windTurbineControllerApi } = useApi();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'));
  const {
    sortModel,
    onSortModelChange,
    searchParams,
    paginationModel,
    onPaginationModelChange,
    onSearch,
    onFilterModelChange,
  } = useDataGrid();

  const loadTurbines = useCallback(async () => {
    const objFromQuery = formatObjFromQuery(fieldsToSend, searchParams);
    const params: WindTurbineControllerApiAllWindTurbinesRequest = {
      ...objFromQuery,
      size: Number(objFromQuery.size) || 10,
      sort: sortingMapping(sorting, objFromQuery.sort as string | undefined),
      status: (objFromQuery.status as string)?.split(
        ',',
      ) as WindTurbineDtoStatusEnum[],
      ratedPower: undefined,
      operationalStatus: (objFromQuery.operationalStatus as string)?.split(
        ',',
      ) as WindTurbineDtoOperationalStatusEnum[],
      connected: undefined,
      accelerationStatus: undefined,
      locationId,
    };
    let result;
    try {
      result = (await windTurbineControllerApi.allWindTurbines(params)).data;
    } catch {
      result = {};
    }

    return result;
  }, [searchParams, windTurbineControllerApi, locationId]);

  const { resource: turbines, isLoading } = useAsyncResourceWithPulling({
    fetchResource: loadTurbines,
    pullingInterval: 30,
  });

  const checkedFilterValues: Record<string, FilterItem> = useMemo(
    () => ({
      status: { value: searchParams.get('status') },
      operationalStatus: { value: searchParams.get('operationalStatus') },
    }),
    [searchParams],
  );

  const columns = useMemo(() => generateColumns({ t }), [t]);

  const rowsPerPageOptions = useMemo(
    () => getRowsPerPage(t, !isDesktop),
    [isDesktop, t],
  );

  const onCellClick = useCallback(
    ({ row, field }: GridCellParams) => {
      if (clickDisabledFields.includes(field)) return;

      navigate(
        getWindTurbinePageLink({
          assetLocationId: row.location?.id,
          assetPublicId: row.publicId,
        }).root,
      );
    },
    [navigate],
  );

  const filterValues = useMemo(() => turbinesFilterValues(t), [t]);

  return (
    <Wrapper className={className} sx={sx}>
      <TableAboveWrapper>
        <Search onSearch={onSearch} sx={{ width: '100%' }} />
        <FilterMenu
          checkedFilterValues={checkedFilterValues}
          filterValues={filterValues}
          onFilterModelChange={onFilterModelChange}
          light
          sx={{ ml: 'auto' }}
        />
      </TableAboveWrapper>
      <AppliedFilters
        checkedFilterValues={checkedFilterValues}
        filterValues={filterValues}
        onFilterModelChange={onFilterModelChange}
      />
      <Box>
        <CommonTable
          columns={columns}
          rows={turbines?.content || []}
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
          sortingMode="server"
          rowCount={turbines?.totalElements || 0}
          onCellClick={onCellClick}
          loading={isLoading}
          sx={{
            maxWidth: '100%',
            '& .MuiDataGrid-row:hover': {
              backgroundColor: 'grey.50',
              cursor: 'pointer',
            },
          }}
        />
        <TablePagination
          count={turbines?.totalElements}
          page={paginationModel.page}
          rowsPerPage={paginationModel.pageSize}
          rowsPerPageOptions={rowsPerPageOptions}
          onPaginationModelChange={onPaginationModelChange}
          sx={{
            ...(!isDesktop && {
              '& .MuiTablePagination-input': { width: 66 },
            }),
          }}
        />
      </Box>
    </Wrapper>
  );
};
