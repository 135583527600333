import { GrafanaPanelParametersDto } from 'openapi-api/admin-service';
import { GrafanaIframeTheme } from 'types/general';

interface Props {
  panelId: string;
  panelParameters: GrafanaPanelParametersDto;
  authToken: string;
  from: number;
  to: number;
  theme?: GrafanaIframeTheme;
}

const getStringFromPanelVariables = (data?: {
  [key: string]: object;
}): string => {
  if (!data) return '';
  const result = Object.entries(data).map(([key, value]) => {
    if (Array.isArray(value)) {
      return value.map((item) => `var-${key}=${item}`).join('&');
    }

    return `var-${key}=${value}`;
  });

  return `&${result.join('&')}`;
};

const getGrafanaWidgetLink = ({
  panelId,
  panelParameters,
  authToken,
  from,
  to,
  theme = GrafanaIframeTheme.light,
}: Props): string => {
  const { host, dashboardUid, dashboardPath, orgId, panelVariables } =
    panelParameters;

  if (!host) return '';

  return `https://${host}/d-solo/${dashboardUid}/${dashboardPath}?orgId=${orgId}&panelId=${panelId}&from=${from}&to=${to}&theme=${theme}${getStringFromPanelVariables(
    panelVariables,
  )}&auth_token=${authToken}`;
};

export default getGrafanaWidgetLink;
