import { FC } from 'react';

const ChevronUp: FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    stroke="#14181F"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 13L12 10L15 13"
      stroke="currentStroke"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ChevronUp;
