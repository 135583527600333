import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const SubmenuItem = styled(Typography)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  cursor: 'pointer',
  color: theme.palette.black[500],
  padding: theme.spacing(3, 2),
  backgroundColor: theme.palette.green[400],
}));

export default SubmenuItem;
