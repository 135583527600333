import { CreateReportFormValues, ReportTimeframes } from './types';
import { SelectItem, TranslationFunction } from 'types/general';

export const defaultValues: CreateReportFormValues = {
  locationNames: [],
  assetIds: [],
  reportPeriod: '',
  reportFromDate: '',
  reportToDate: '',
};

export const getTimeframeSelectItems = (
  t: TranslationFunction,
): SelectItem[] => {
  return [
    {
      label: t`pages.reports.form.timeframes.today`,
      value: ReportTimeframes.today,
    },
    {
      label: t`pages.reports.form.timeframes.yesterday`,
      value: ReportTimeframes.yesterday,
    },
    {
      label: t`pages.reports.form.timeframes.lastWeek`,
      value: ReportTimeframes.lastWeek,
    },
    {
      label: t`pages.reports.form.timeframes.lastMonth`,
      value: ReportTimeframes.lastMonth,
    },
  ];
};
