import { styled } from '@mui/material';

import CheckMark from 'components/icons/CheckMark';

const CheckMarkIcon = styled(CheckMark, {
  shouldForwardProp: (propName) => propName !== 'isActive',
})<{ isActive?: boolean }>(({ theme, isActive = false }) => ({
  '& > path': {
    stroke: isActive ? theme.palette.green[600] : theme.palette.grey[200],
  },
}));

export default CheckMarkIcon;
