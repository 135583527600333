export enum WIDGETS {
  WIND_SPEED = 35,
  GENERATOR_SPEED = 36,
  ACTIVE_POWER = 23,
  WIND_SPEED_CHART = 12,
  ACTIVE_POWER_CHART = 16,
  ACTIVE_POWER_COUNTER = 58,
  NETWORK_PARAMETERS_VERTICAL = 17,
  ELECTRICAL_PARAMETERS_VERTICAL = 24,
  SUMMARY_PARAMETERS_VERTICAL = 14,
  STATUSES = 12,
  STATUSES_MOBILE = 30,
  ERRORS = 29,
  ERRORS_MOBILE = 31,
}
