import { Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { Trans } from 'react-i18next';

import { WTLink } from './WTLink';
import {
  AssetOperationalStatusChangedDto,
  AssetOperationalStatusChangedDtoAllOfOperationalStatusEnum,
} from 'openapi-api/admin-service';
import { getStringFromISODate } from 'utils/functions/getStringFromISODate';

interface Props {
  details: AssetOperationalStatusChangedDto;
  extended: boolean;
  locationId?: number | string;
}

const TemplateOperationalStatusChanged: FC<Props> = ({
  details,
  extended,
  locationId,
}) => {
  const { publicId, eventDateTime, assetNameForUser } = details;

  const i18nKey = useMemo(() => {
    if (
      details.operationalStatus ===
      AssetOperationalStatusChangedDtoAllOfOperationalStatusEnum.ACTIVATED
    ) {
      return 'notifications.assetOperationalStatusChangedOn';
    }
    return 'notifications.assetOperationalStatusChangedOff';
  }, [details.operationalStatus]);

  if (!publicId) {
    return null;
  }

  return (
    <>
      <Typography variant={extended ? 'bodyM' : 'bodyS'}>
        <Trans
          i18nKey={i18nKey}
          values={{ eventDateTime: getStringFromISODate(eventDateTime) }}
          components={[
            <WTLink
              key={`operationalStatusChanged-link-${publicId}`}
              publicId={publicId}
              linkText={assetNameForUser}
              locationId={locationId}
              newTab={extended}
            />,
          ]}
        />
      </Typography>
    </>
  );
};

export default TemplateOperationalStatusChanged;
