import { SxProps, Tab, Typography } from '@mui/material';
import { FC, ReactElement, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { TabsStyled } from './styles';
import { TabType, TabsComponentVariant } from './types';
import DropdownMenu from '../DropdownMenu';
import MainMenuChevronDown from 'components/icons/MainMenuChevronDown';
import MainMenuChevronUp from 'components/icons/MainMenuChevronUp';

interface TabsProps {
  tabs: TabType[];
  componentVariant: TabsComponentVariant;
  className?: string;
  sx?: SxProps;
}

function a11yProps(value: string) {
  return {
    id: `admin-tab-${value}`,
    'aria-controls': `admin-tabpanel-${value}`,
  };
}

const Tabs: FC<TabsProps> = ({
  tabs,
  componentVariant,
  className,
  sx,
}): ReactElement => {
  const { pathname } = useLocation();
  const [value, setValue] = useState(pathname);

  useEffect(() => {
    setValue(pathname);
  }, [pathname]);

  return (
    <TabsStyled
      value={value}
      aria-label="tabs"
      componentVariant={componentVariant}
      className={className}
      sx={sx}
    >
      {tabs.map((tab) =>
        !!tab.submenu ? (
          <DropdownMenu
            key={tab.value}
            sx={(theme) => ({
              borderBottom: '2px solid transparent',
              borderBottomColor: tab.submenu?.some(
                (s) => s.pattern?.test(pathname),
              )
                ? theme.palette.white.main
                : 'transparent',
              px: '1px',
            })}
            title={<Typography variant="subheading">{tab.label}</Typography>}
            items={tab.submenu}
            withExpandIcon
            customIcons={{
              down: <MainMenuChevronDown />,
              up: <MainMenuChevronUp />,
            }}
          />
        ) : (
          <Tab
            key={`${tab.label}-${tab.value}`}
            value={tab.value}
            label={tab.label}
            component={Link}
            to={tab.value}
            disabled={tab.disabled}
            disableRipple
            {...a11yProps(tab.value.split('/').pop()!)}
          />
        ),
      )}
    </TabsStyled>
  );
};

export default Tabs;
