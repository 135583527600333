import { useOutlet } from 'react-router-dom';

import { HeadBlock } from './components';
import WindTurbinePageTabs from './components/WindTurbinePageTabs';
import { PageWrapper, TabsContent } from './styles';
import { WindTurbineProvider } from 'contexts/WindTurbine';

const WindTurbinePage = () => {
  const outlet = useOutlet();

  return (
    <WindTurbineProvider>
      <PageWrapper>
        <HeadBlock />
        <WindTurbinePageTabs />
        <TabsContent>{outlet}</TabsContent>
      </PageWrapper>
    </WindTurbineProvider>
  );
};

export { WindTurbinePage };
