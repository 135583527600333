import { styled } from '@mui/material';

import Close from 'components/icons/Close';

const CloseIcon = styled(Close)(({ theme }) => ({
  cursor: 'pointer',

  '& > path': {
    stroke: theme.palette.grey[500],
  },
}));

export default CloseIcon;
