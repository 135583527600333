import { alpha, styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

const SearchTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'light',
})<{ light?: boolean }>(({ theme, light = false }) => ({
  borderRadius: '57px',
  height: '48px',
  width: '332px',
  backgroundColor: light
    ? alpha(theme.palette.white.main, 0.2)
    : theme.palette.white.main,
  border: `1px solid ${
    light ? theme.palette.green[100] : theme.palette.grey[100]
  }`,
  '& .MuiInputBase-root': {
    padding: 0,
    paddingRight: theme.spacing(4),
    height: '100%',
    background: 'none',
    '&:hover': {
      background: 'none',
    },
    '& .MuiSvgIcon-root': {
      color: light ? theme.palette.green[100] : theme.palette.grey[400],
    },
  },
  '& .MuiSvgIcon-root': {
    color: light ? theme.palette.green[100] : theme.palette.grey[400],
  },
  '& .MuiInputBase-input': {
    height: '100%',
    fontSize: '16px',
    fontWeight: 400,
    color: theme.palette.black[500],
    lineHeight: '150%',
    fontFamily: 'DM Sans',
    padding: 0,
    paddingLeft: theme.spacing(0.5),
    '&::placeholder': {
      opacity: 'unset',
      color: light ? theme.palette.grey[400] : theme.palette.grey[500],
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    display: 'none',
  },
  [theme.breakpoints.down('desktop')]: {
    width: '100%',
  },
}));

export default SearchTextField;
