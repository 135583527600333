import { Grid, SxProps, Typography } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';

import { NotificationChip } from './NotificationChip';
import { UnreadStatusLine, Wrapper } from './styles';
import { TemplateBottomRow } from './styles/TemplateBottomRow';
import TemplateAssetConnectionError from './TemplateAssetConnectionError';
import TemplateAssetConnectionErrorResolved from './TemplateAssetConnectionErrorResolved';
import TemplateAssetErrorFired from './TemplateAssetErrorFired';
import TemplateAssetErrorResolved from './TemplateAssetErrorResolved';
import TemplateControllerConnected from './TemplateControllerConnected';
import TemplateControllerDisconnected from './TemplateControllerDisconnected';
import TemplateOperationalStatusChanged from './TemplateOperationalStatusChanged';
import TemplateReportReady from './TemplateReportReady';
import TemplateTagsCollectingError from './TemplateTagsCollectingError';
import TemplateTagsCollectingErrorResolved from './TemplateTagsCollectingErrorResolved';
import { TemplateWeatherWarning } from './TemplateWeatherWarning';
import {
  AssetActivePowerDeflectionErrorDtoAllOfEventEnum as AssetEventEnum,
  AssetConnectionErrorDto,
  AssetErrorFiredDto,
  AssetErrorResolvedDto,
  AssetOperationalStatusChangedDto,
  AssetOperationalStatusChangedDtoAllOfEventEnum,
  AssetTagsCollectionErrorDto,
  AssetTagsCollectionErrorResolvedDto,
  NotificationDto,
  ReportReadyDto,
  ControllerConnectedDto,
  ControllerDisconnectedDto,
  WeatherWarningDto,
} from 'openapi-api/admin-service';
import { getStringFromISODate } from 'utils/functions/getStringFromISODate';

interface Props {
  notification: NotificationDto;
  onClick?: (notification: NotificationDto) => void;
  extended?: boolean;
  sx?: SxProps;
}

const NotificationMessage: FC<Props> = ({
  notification,
  onClick,
  extended = false,
  sx,
}) => {
  const { details, markedAsRead, locationId } = notification;
  const handleClick = useCallback(
    () => notification?.id && onClick && onClick(notification),
    [notification, onClick],
  );

  const notificationTemplate = useMemo(() => {
    if (details?.event === AssetEventEnum.ASSET_CONNECTION_ERROR) {
      return (
        <TemplateAssetConnectionError
          details={details as AssetConnectionErrorDto}
          extended={extended}
          locationId={locationId}
        />
      );
    }

    if (details?.event === AssetEventEnum.ASSET_ERROR_FIRED) {
      return (
        <TemplateAssetErrorFired
          details={details as AssetErrorFiredDto}
          extended={extended}
          locationId={locationId}
        />
      );
    }

    if (details?.event === AssetEventEnum.ASSET_ERROR_RESOLVED) {
      return (
        <TemplateAssetErrorResolved
          details={details as AssetErrorResolvedDto}
          extended={extended}
          locationId={locationId}
        />
      );
    }

    if (details?.event === AssetEventEnum.ASSET_CONNECTION_ERROR_RESOLVED) {
      return (
        <TemplateAssetConnectionErrorResolved
          details={details as AssetConnectionErrorDto}
          extended={extended}
          locationId={locationId}
        />
      );
    }

    if (details?.event === AssetEventEnum.ASSET_TAGS_COLLECTION_ERROR) {
      return (
        <TemplateTagsCollectingError
          details={details as AssetTagsCollectionErrorDto}
          extended={extended}
          locationId={locationId}
        />
      );
    }
    if (
      details?.event ===
      AssetOperationalStatusChangedDtoAllOfEventEnum.ASSET_OPERATIONAL_STATUS_CHANGED
    ) {
      return (
        <TemplateOperationalStatusChanged
          details={details as AssetOperationalStatusChangedDto}
          extended={extended}
          locationId={locationId}
        />
      );
    }

    if (
      details?.event === AssetEventEnum.ASSET_TAGS_COLLECTION_ERROR_RESOLVED
    ) {
      return (
        <TemplateTagsCollectingErrorResolved
          details={details as AssetTagsCollectionErrorResolvedDto}
          extended={extended}
          locationId={locationId}
        />
      );
    }

    if (details?.event === AssetEventEnum.REPORT_READY) {
      return (
        <TemplateReportReady
          details={details as unknown as ReportReadyDto}
          extended={extended}
        />
      );
    }

    if (details?.event === AssetEventEnum.CONTROLLER_DISCONNECTED) {
      return (
        <TemplateControllerDisconnected
          details={details as ControllerDisconnectedDto}
          extended={extended}
          locationId={locationId}
        />
      );
    }

    if (details?.event === AssetEventEnum.CONTROLLER_CONNECTED) {
      return (
        <TemplateControllerConnected
          details={details as ControllerConnectedDto}
          extended={extended}
          locationId={locationId}
        />
      );
    }

    if (details?.event === AssetEventEnum.WEATHER_WARNING) {
      return (
        <TemplateWeatherWarning
          details={details as unknown as WeatherWarningDto}
          extended={extended}
        />
      );
    }
  }, [details, extended, locationId]);

  if (!details || !notificationTemplate) {
    return null;
  }

  return (
    <Wrapper
      sx={sx}
      onClick={handleClick}
      data-testid="notification-wrapper"
      extended={extended}
    >
      {!markedAsRead && <UnreadStatusLine extended={extended} />}
      {extended && (
        <Grid
          sx={{ mb: 2 }}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <NotificationChip notification={notification} />
          <Typography color="grey.600" variant="bodyS">
            {getStringFromISODate(notification.details?.eventDateTime)}
          </Typography>
        </Grid>
      )}
      {!!notificationTemplate && (
        <TemplateBottomRow extended={extended}>
          {notificationTemplate}
        </TemplateBottomRow>
      )}
    </Wrapper>
  );
};

export default NotificationMessage;
