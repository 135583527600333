import { styled } from '@mui/material';

import { StatusFilterValues } from '../types';

export const StatusFilterItemCircle = styled('div', {
  shouldForwardProp: (propName) => propName !== 'filterValue',
})<{ filterValue?: StatusFilterValues }>(({ theme, filterValue }) => {
  let color;
  switch (filterValue) {
    case StatusFilterValues.ACTIVE:
      color = theme.palette.green['600'];
      break;
    case StatusFilterValues.INACTIVE:
      color = theme.palette.grey['400'];
      break;
    case StatusFilterValues.ERROR:
      color = theme.palette.error.main;
      break;
    default:
      color = 'transparent';
  }

  return {
    display: filterValue !== StatusFilterValues.TOTAL ? 'block' : 'none',
    backgroundColor: color,
    width: '12px',
    height: '12px',
    borderRadius: '12px',

    [theme.breakpoints.down('desktop')]: {
      display: 'none',
    },
  };
});
