import { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';

import Context from './Context';
import { NotificationPopup } from 'components/common/NotificationPopup';
import { NotificationPopupProps } from 'components/common/NotificationPopup/types';

export type ResponsePopupState = NotificationPopupProps;

const ResponsePopupProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [popupState, setPopupState] = useState<ResponsePopupState>();

  const openPopup = useCallback((state: ResponsePopupState) => {
    setIsOpen(true);
    setPopupState(state);
  }, []);

  const closePopup = useCallback(() => {
    setIsOpen(false);
  }, []);

  const contextValue = useMemo(
    () => ({
      openPopup,
      closePopup,
    }),
    [openPopup, closePopup],
  );

  return (
    <Context.Provider value={contextValue}>
      <NotificationPopup
        key={popupState?.title}
        variant={popupState?.variant}
        title={popupState?.title}
        subtitle={popupState?.subtitle}
        primaryButton={popupState?.primaryButton}
        secondaryButton={popupState?.secondaryButton}
        isOpen={isOpen}
        onClose={closePopup}
      />
      {children}
    </Context.Provider>
  );
};

export default ResponsePopupProvider;
