import { FC } from 'react';

const Checked: FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="3"
      y="3"
      width="18"
      height="18"
      rx="3"
      fill="#A4E256"
      stroke="#A4E256"
      strokeWidth="2"
    />
    <path d="M6 10.8571L11.1429 16L18 8" stroke="#21252C" strokeWidth="2" />
  </svg>
);

export default Checked;
