import { FC } from 'react';

import { BreadcrumbLink, BreadcrumbsStyled, IconArrowWrapper } from './styles';
import { BreadcrumbItem } from './types';

interface Props {
  items: BreadcrumbItem[];
}

const Breadcrumbs: FC<Props> = ({ items }) => {
  return (
    <BreadcrumbsStyled aria-label="breadcrumb">
      {items.map((item, index) => (
        <BreadcrumbLink
          key={item.label + item.path}
          to={item.path}
          active={items.length - 1 === index}
        >
          {index === 0 ? <IconArrowWrapper /> : null}
          {item.label}
        </BreadcrumbLink>
      ))}
    </BreadcrumbsStyled>
  );
};

export default Breadcrumbs;
