const windTurbinePaths = (rootPath: string, publicId: string | number) => {
  const wtRootPath = `${rootPath}/wind-turbines/${publicId}`;

  return {
    root: wtRootPath,
    general: `${wtRootPath}/general`,
    statuses: `${wtRootPath}/statuses`,
    errorReport: `${wtRootPath}/errorReport`,
  };
};

const routePaths = {
  root: '/',
  parks: {
    root: '/parks',
    specific: (locationId: string | number) => {
      const parkRootPath = `${routePaths.parks.root}/${locationId}`;

      return {
        root: parkRootPath,
        windTurbine: (publicId: string | number) =>
          windTurbinePaths(parkRootPath, publicId),
      };
    },
  },
  login: '/login',
  reports: '/reports',
  notifications: (type: string) => `/notifications/${type}`,
};

export default routePaths;
