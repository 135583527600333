import { Grid, SxProps, Typography } from '@mui/material';
import { FC } from 'react';

import { WindTurbineParametersWrapper } from './styles';
import { WIDGETS } from '../../constants';
import { GrafanaIframe } from '../GrafanaIframe';

interface Props {
  name: string;
  widget: WIDGETS;
  height?: number;
  sx?: SxProps;
}

export const WindTurbineParameters: FC<Props> = ({
  name,
  widget,
  height,
  sx,
}) => {
  return (
    <Grid container flexDirection="column" sx={sx}>
      <Typography variant="h4" sx={{ mb: 3 }}>
        {name}
      </Typography>
      <WindTurbineParametersWrapper height={height}>
        <GrafanaIframe panelId={widget} />
      </WindTurbineParametersWrapper>
    </Grid>
  );
};
