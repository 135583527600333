/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface AssetOperationalStatusChangedDtoAllOf
 */
export interface AssetOperationalStatusChangedDtoAllOf {
    /**
     * 
     * @type {string}
     * @memberof AssetOperationalStatusChangedDtoAllOf
     */
    'event'?: AssetOperationalStatusChangedDtoAllOfEventEnum;
    /**
     * 
     * @type {string}
     * @memberof AssetOperationalStatusChangedDtoAllOf
     */
    'publicId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetOperationalStatusChangedDtoAllOf
     */
    'assetNameForUser'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetOperationalStatusChangedDtoAllOf
     */
    'assetNameForAdmin'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetOperationalStatusChangedDtoAllOf
     */
    'eventDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetOperationalStatusChangedDtoAllOf
     */
    'operationalStatus'?: AssetOperationalStatusChangedDtoAllOfOperationalStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AssetOperationalStatusChangedDtoAllOfEventEnum {
    ASSET_ERROR_FIRED = 'ASSET_ERROR_FIRED',
    ASSET_ERROR_RESOLVED = 'ASSET_ERROR_RESOLVED',
    ASSET_CONNECTION_ERROR = 'ASSET_CONNECTION_ERROR',
    ASSET_CONNECTION_ERROR_RESOLVED = 'ASSET_CONNECTION_ERROR_RESOLVED',
    REPORT_READY = 'REPORT_READY',
    ASSET_ADDED = 'ASSET_ADDED',
    ASSET_TAGS_COLLECTION_ERROR = 'ASSET_TAGS_COLLECTION_ERROR',
    ASSET_TAGS_COLLECTION_ERROR_RESOLVED = 'ASSET_TAGS_COLLECTION_ERROR_RESOLVED',
    ASSET_OPERATIONAL_STATUS_CHANGED = 'ASSET_OPERATIONAL_STATUS_CHANGED',
    ASSET_ACTIVE_POWER_DEFLECTION_ERROR = 'ASSET_ACTIVE_POWER_DEFLECTION_ERROR',
    ASSET_ACTIVE_POWER_DEFLECTION_ERROR_RESOLVED = 'ASSET_ACTIVE_POWER_DEFLECTION_ERROR_RESOLVED',
    CONTROLLER_DISCONNECTED = 'CONTROLLER_DISCONNECTED',
    CONTROLLER_CONNECTED = 'CONTROLLER_CONNECTED',
    WEATHER_WARNING = 'WEATHER_WARNING',
    INVESTIGATION_DASHBOARD_READY = 'INVESTIGATION_DASHBOARD_READY'
}
/**
    * @export
    * @enum {string}
    */
export enum AssetOperationalStatusChangedDtoAllOfOperationalStatusEnum {
    NOT_SET = 'NOT_SET',
    ACTIVATED = 'ACTIVATED',
    DEACTIVATED = 'DEACTIVATED'
}


