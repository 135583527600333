import { Box, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ReportItemChip } from './ReportItemChip';
import ChipWrapper from './styles/ChipWrapper';
import ReportItemWrapper from './styles/ReportItemWrapper';
import CommonButton from 'components/common/CommonButton';
import Download from 'components/icons/Download';
import {
  ReportRequestHistoryDto,
  ReportRequestHistoryDtoStatusEnum,
} from 'openapi-api/report-service';

const datetimeFormat = 'DD/MM/YYYY HH:mm';

interface Props {
  item: ReportRequestHistoryDto;
}

dayjs.extend(utc);

export const ReportItem: FC<Props> = ({ item }) => {
  const { t } = useTranslation();

  return (
    <ReportItemWrapper>
      <ChipWrapper>
        <ReportItemChip status={item.status} />
        <Typography
          variant="bodyS"
          color="grey.600"
          sx={{ display: { mobile: 'block', desktop: 'none' } }}
        >
          {dayjs.utc(item.createDatetime).local().format(datetimeFormat)}
        </Typography>
      </ChipWrapper>
      <Grid
        gap={1.25}
        display="flex"
        flexDirection="column"
        flexGrow={1}
        sx={{ wordBreak: { mobile: 'break-word', tablet: 'normal' } }}
      >
        <Typography variant="bodyM">{item.reportName}</Typography>
        <Typography
          variant="bodyS"
          color="grey.600"
          sx={{ display: { mobile: 'none', desktop: 'block' } }}
        >
          {dayjs.utc(item.createDatetime).local().format(datetimeFormat)}
        </Typography>
      </Grid>
      {item.status === ReportRequestHistoryDtoStatusEnum.READY && (
        <Box
          component="a"
          download
          href={item.downloadUrl}
          target="_blank"
          sx={{ width: { mobile: '100%', tablet: 'auto' }, flexShrink: 0 }}
        >
          <CommonButton
            variant="outlined"
            sx={{ width: { mobile: '100%', tablet: 'auto' } }}
            startIcon={<Download />}
          >{t`pages.reports.downloadCsv`}</CommonButton>
        </Box>
      )}
    </ReportItemWrapper>
  );
};
