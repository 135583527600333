import dayjs from 'dayjs';

import { TranslationFunction } from 'types/general';

export const getWeatherDateNaming = ({
  datetime,
  t,
}: {
  datetime?: string;
  t: TranslationFunction;
}): string => {
  const dayMonthDate = dayjs(datetime).format('DD.MM');
  const isToday = dayjs().isSame(datetime, 'day');

  if (isToday) return `${t('weather.daysOfWeek.today')} ${dayMonthDate}`;

  const dayName = dayjs(datetime).format('d');
  return `${t(`weather.daysOfWeek.${dayName}`)} ${dayMonthDate}`;
};

export const getUvIndexTitle = ({
  index = 0,
  t,
}: {
  index?: number;
  t: TranslationFunction;
}): string => {
  switch (true) {
    case index < 3:
      return t('weather.uv.low');
    case index < 6:
      return t('weather.uv.moderate');
    case index < 8:
      return t('weather.uv.high');
    case index < 11:
      return t('weather.uv.veryHigh');
    case index >= 11:
      return t('weather.uv.extreme');
    default:
      return 'undefined';
  }
};
