import { styled } from '@mui/material/styles';

const MobileMenuWrapper = styled('div')(({ theme }) => ({
  position: 'fixed',
  width: '100vw',
  height: '100vh',
  zIndex: 2000,
  left: 0,
  top: 0,
  display: 'grid',
  gridTemplateRows: '56px 1fr',
  backgroundColor: theme.palette.green['500'],
}));

export default MobileMenuWrapper;
