import { createTheme } from '@mui/material/styles';

import {
  black100,
  black200,
  black300,
  black400,
  black50,
  black500,
  black600,
  black700,
  black800,
  black900,
  error,
  green100,
  green200,
  green300,
  green400,
  green50,
  green500,
  green600,
  green700,
  green800,
  green900,
  grey100,
  grey20,
  grey200,
  grey300,
  grey400,
  grey50,
  grey500,
  grey600,
  grey700,
  grey800,
  grey900,
  neutralLight,
  secondaryWarning,
  secondaryWarning200,
  warning20,
  whiteMain,
  yellow200,
} from './colors';
import CheckedIcon from 'components/icons/CheckedIcon';

const baseTheme = createTheme({
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 768,
      desktop: 1440,
    },
  },
  palette: {
    primary: {
      main: green500,
      light: neutralLight,
    },
    error: {
      main: error,
    },
    warning: {
      main: secondaryWarning,
      20: warning20,
      200: secondaryWarning200,
    },
    grey: {
      20: grey20,
      50: grey50,
      100: grey100,
      200: grey200,
      300: grey300,
      400: grey400,
      500: grey500,
      600: grey600,
      700: grey700,
      800: grey800,
      900: grey900,
    },
    green: {
      50: green50,
      100: green100,
      200: green200,
      300: green300,
      400: green400,
      500: green500,
      600: green600,
      700: green700,
      800: green800,
      900: green900,
    },
    white: {
      main: whiteMain,
    },
    black: {
      50: black50,
      100: black100,
      200: black200,
      300: black300,
      400: black400,
      500: black500,
      600: black600,
      700: black700,
      800: black800,
      900: black900,
    },
    yellow: {
      200: yellow200,
    },
  },
});

const customTheme = createTheme({
  ...baseTheme,
  palette: {
    ...baseTheme.palette,
    mode: 'light',
  },

  spacing: 8,

  typography: {
    h1: {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '40px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '115%',
      letterSpacing: '0.4px',
      [baseTheme.breakpoints.down('desktop')]: {
        fontSize: '36px',
      },
    },
    h2: {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '32px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '120%',
      [baseTheme.breakpoints.down('desktop')]: {
        fontSize: '28px',
      },
    },
    h3: {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '130%',
    },
    h4: {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '130%',
    },
    h5: {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '130%',
    },
    subheading: {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '110%',
      letterSpacing: '0.36px',
      textTransform: 'uppercase',
    },
    bodyXXL: {
      fontFamily: 'DM Sans',
      fontSize: '22px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '150%',
    },
    bodyXL: {
      fontFamily: 'DM Sans',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '150%',
    },
    bodyL: {
      fontFamily: 'DM Sans',
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '150%',
    },
    bodyM: {
      fontFamily: 'DM Sans',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '150%',
    },
    bodyS: {
      fontFamily: 'DM Sans',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '150%',
    },
    bodyXS: {
      fontFamily: 'DM Sans',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '150%',
    },
    bodyXXLStrong: {
      fontFamily: 'DM Sans',
      fontSize: '22px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '150%',
    },
    bodyXLStrong: {
      fontFamily: 'DM Sans',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '150%',
    },
    bodyLStrong: {
      fontFamily: 'DM Sans',
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '150%',
    },
    bodyMStrong: {
      fontFamily: 'DM Sans',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '150%',
    },
    bodySStrong: {
      fontFamily: 'DM Sans',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '150%',
    },
    bodyXSStrong: {
      fontFamily: 'DM Sans',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '150%',
    },
    buttonPrimary: {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '150%',
      letterSpacing: '0.48px',
      textTransform: 'uppercase',
    },
    buttonS: {
      fontFamily: 'DM Sans',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '150%',
      letterSpacing: 'normal',
      textTransform: 'uppercase',
    },
  },
  zIndex: {
    globalLoader: 9999,
    dialog: 1300,
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          width: '100%',
          fontFamily: 'DM Sans',
          fontSize: '14px',
          fontWeight: 400,
          borderRadius: baseTheme.spacing(1.5),
        },
        standardError: {
          backgroundColor: warning20,
          color: baseTheme.palette.black['600'],
          border: `1px solid ${secondaryWarning}`,
        },
      },
    },
    MuiButton: {
      defaultProps: { variant: 'contained', size: 'large', color: 'primary' },
      styleOverrides: {
        root: ({ theme }) => ({
          gap: theme.spacing(1),
        }),
        startIcon: {
          margin: '0',
        },
        endIcon: {
          margin: '0',
        },
      },
      variants: [
        {
          props: { variant: 'contained' },
          style: ({ theme }) => ({
            borderRadius: '25px',
            color: theme.palette.black[500],

            '&.Mui-disabled': {
              color: theme.palette.black[500],
              opacity: '0.45',
            },
          }),
        },
        {
          props: { variant: 'contained', color: 'primary' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.green[500],
            '&:hover': {
              backgroundColor: theme.palette.green[600],
            },
            '&:focus': {
              outline: `4px solid ${theme.palette.grey[300]}`,
            },
            '&.Mui-disabled': {
              backgroundColor: theme.palette.green[500],
            },
          }),
        },
        {
          props: { variant: 'contained', color: 'white' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.white.main,
            '&:hover': {
              backgroundColor: theme.palette.grey[100],
            },
            '&:focus': {
              outline: `4px solid ${theme.palette.grey[300]}`,
            },
            '&.Mui-disabled': {
              opacity: 0.45,
            },
          }),
        },
        {
          props: { variant: 'contained', color: 'black' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.black[600],
            color: theme.palette.white.main,
            '&:hover': {
              backgroundColor: theme.palette.grey[900],
            },
            '&:focus': {
              outline: `4px solid ${theme.palette.grey[300]}`,
            },
            '&.Mui-disabled': {
              backgroundColor: theme.palette.black[600],
              color: theme.palette.white.main,
              opacity: 0.3,
            },
          }),
        },
        {
          props: { variant: 'outlined' },
          style: ({ theme }) => ({
            border: 'none',
            borderRadius: '27px',
            outline: `2px solid ${theme.palette.grey[100]}`,
            backgroundColor: theme.palette.white.main,
            color: theme.palette.black[600],

            '&:hover': {
              border: 'none',
              outlineColor: theme.palette.grey[300],
              backgroundColor: theme.palette.white.main,
            },
            '&:focus': {
              outlineWidth: '4px',
            },
            '&:disabled': {
              color: theme.palette.grey[200],
            },
          }),
        },
        {
          props: { variant: 'outlined', color: 'white' },
          style: ({ theme }) => ({
            outline: `2px solid ${theme.palette.grey[700]}`,
            color: theme.palette.white.main,

            '&:hover': {
              border: 'none',
              outlineColor: theme.palette.grey[300],
              backgroundColor: theme.palette.grey[400],
            },
          }),
        },
        {
          props: { variant: 'text', size: 'text' },
          style: ({ theme }) => ({
            ...theme.typography.buttonPrimary,
            padding: 0,
            textTransform: 'none',
            lineHeight: '137.5%',
            color: theme.palette.grey[800],

            '&: hover': {
              color: theme.palette.green[600],
            },
          }),
        },
        {
          props: { variant: 'text' },
          style: ({ theme }) => ({
            color: theme.palette.grey[800],
          }),
        },
        {
          props: { size: 'small' },
          style: ({ theme }) => ({
            ...theme.typography.buttonS,
            padding: theme.spacing(1, 2),
          }),
        },
        {
          props: { variant: 'text', color: 'error' },
          style: ({ theme }) => ({
            ...theme.typography.buttonPrimary,
            color: theme.palette.error.main,
            textTransform: 'none',

            '&: hover': {
              color: theme.palette.warning.main,
            },
          }),
        },
        {
          props: { size: 'large' },
          style: ({ theme }) => ({
            ...theme.typography.buttonPrimary,
            height: '50px',
            minHeight: '50px',
            padding: theme.spacing(0, 3.75),
          }),
        },
        {
          props: { size: 'medium' },
          style: ({ theme }) => ({
            ...theme.typography.buttonS,
            height: 'auto',
            letterSpacing: 'normal',
            padding: theme.spacing(1, 2),
          }),
        },
      ],
    },
    MuiChip: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiChip-label': {
            ...theme.typography.subheading,
            color: theme.palette.black[900],
          },
        }),
        colorError: {
          backgroundColor: secondaryWarning200,
        },
        colorWarning: {
          backgroundColor: baseTheme.palette.yellow[200],
        },
        colorSuccess: { backgroundColor: baseTheme.palette.green[200] },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.grey[300],
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ theme }) => ({
          position: 'relative',
          outline: `2px solid transparent`,
          outlineOffset: '-2px',
          '&.Mui-error': {
            outlineColor: theme.palette.error.main,
          },
          '&': {
            paddingRight: theme.spacing(2),
            borderRadius: '12px',
            fontFamily: 'DM Sans',
            fontSize: '16px',
            overflow: 'hidden',
            '&, &:hover': {
              backgroundColor: theme.palette.grey[50],
            },
          },
          textarea: {
            padding: 0,
          },
        }),
        multiline: ({ theme }) => ({
          '&': {
            padding: theme.spacing(2.875, 2, 1.125),
          },
        }),
        input: ({ theme }) => ({
          '&': {
            fontSize: '16px',
            height: '24px',
            // TODO: apply only if input is labelled
            padding: theme.spacing(2.875, 2, 1.125),
            color: theme.palette.black[500],
            '&:-webkit-autofill': {
              '&, &:focus': {
                WebkitBoxShadow: `0 0 0 30px ${theme.palette.grey[50]} inset`,
              },
            },
          },
        }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontFamily: 'DM Sans',
          lineHeight: '150%',
          transform: 'translate(16px, 16px) scale(1)',
          color: theme.palette.grey['800'],
          '&.Mui-focused, &.Mui-error': {
            color: theme.palette.grey['800'],
          },
        }),
        shrink: {
          lineHeight: '100%',
          transform: 'translate(16px, 7px) scale(0.75)',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: () => ({
          '&:not(.Mui-error)': {
            display: 'none',
          },
        }),
      },
    },
    MuiPopper: {
      styleOverrides: {
        root: () => ({
          '&.MuiPickersPopper-root': {
            zIndex: 9999,
          },
        }),
      },
    },
    MuiRadio: {
      defaultProps: { checkedIcon: <CheckedIcon /> },
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.grey[300],
          '&.Mui-checked': {
            color: theme.palette.green[500],
          },
        }),
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme }) => ({
          padding: theme.spacing(0.75, 1),
          backgroundColor: theme.palette.black[500],
          ...theme.typography.bodyS,
          color: theme.palette.white.main,
        }),
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.black[500],
        }),
      },
    },
  },
});

export default customTheme;
