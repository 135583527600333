import { styled } from '@mui/material/styles';

const TabWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'disabled',
})<{ disabled?: boolean }>(({ theme, disabled = false }) => ({
  padding: theme.spacing(2, 0),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'column',
  position: 'relative',
  cursor: disabled ? 'auto' : 'pointer',
  width: '100%',
  minWidth: '335px',
  borderBottom: `1px solid ${theme.palette.green['200']}`,
  '& h4': {
    color: disabled ? theme.palette.grey[600] : theme.palette.black[500],
  },

  [theme.breakpoints.down('tablet')]: {
    padding: theme.spacing(3, 0),
  },
}));

export default TabWrapper;
