import { FC, PropsWithChildren, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
  IWindTurbinePanelsContext,
  WindTurbinePanelsContext,
} from './WindTurbinePanelsContext';
import useApi from 'contexts/api';
import { useAsyncResourceWithPulling } from 'utils/hooks/useAsyncResourceWithPulling';

interface Props {
  dashboardType: 'GENERAL' | 'ERRORS_STATUSES';
}

const WindTurbinePanelsProvider: FC<PropsWithChildren<Props>> = ({
  dashboardType,
  children,
}) => {
  const { publicId } = useParams();
  const { assetControllerApi } = useApi();

  const getPanels = useCallback(async () => {
    if (typeof publicId === 'undefined') {
      return;
    }

    try {
      const data = (
        await assetControllerApi.getAssetGrafanaPanels({
          assetPublicId: publicId,
          dashboardType,
        })
      ).data;
      return data;
    } catch {}
  }, [publicId, assetControllerApi, dashboardType]);

  const { resource, isLoading } = useAsyncResourceWithPulling({
    fetchResource: getPanels,
    pullingInterval: 30,
  });

  const contextValue: IWindTurbinePanelsContext = useMemo(
    () => ({
      panels: resource,
      isLoading,
    }),
    [resource, isLoading],
  );

  return (
    <WindTurbinePanelsContext.Provider value={contextValue}>
      {children}
    </WindTurbinePanelsContext.Provider>
  );
};

export default WindTurbinePanelsProvider;
