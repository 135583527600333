import { useContext } from 'react';

import ApiContext from './Context';
import ApiProvider from './Provider';

const useApi = () => {
  const context = useContext(ApiContext);

  if (typeof context === 'undefined') {
    throw new Error('useApi must be used within an ApiProvider');
  }

  return context;
};

export default useApi;
export { ApiProvider };
