import { styled } from '@mui/material';

const Wrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(6.5),

  [theme.breakpoints.down('desktop')]: {
    marginBottom: theme.spacing(12),
  },

  [theme.breakpoints.down('tablet')]: {
    gap: theme.spacing(5),
  },
}));

export default Wrapper;
