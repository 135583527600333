import { styled } from '@mui/material';

const ScrollableWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'height',
})<{ height: number }>(({ theme, height }) => ({
  height: `${height}px`,
  overflow: 'auto',

  [theme.breakpoints.down('desktop')]: {
    height: 'unset',
  },
}));

export default ScrollableWrapper;
