const MainMenuChevronDown = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.83203 8.83333L9.9987 13L14.1654 8.83333"
      stroke="#3239444D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default MainMenuChevronDown;
