import React, { useMemo, useState, FC, PropsWithChildren } from 'react';

import GlobalDataContext from './Context';
import { usePopup } from '../../utils/hooks/usePopup';

const GlobalDataProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isUpdatePasswordOpen, openUpdatePassword, closeUpdatePassword] =
    usePopup();
  const [isSendSupportRequestOpened, setIsSendSupportRequestOpened] =
    useState(false);

  const contextValue = useMemo(
    () => ({
      isMobileMenuOpen,
      setIsMobileMenuOpen,
      isUpdatePasswordOpen,
      openUpdatePassword,
      closeUpdatePassword,
      isSendSupportRequestOpened,
      setIsSendSupportRequestOpened,
    }),
    [
      isMobileMenuOpen,
      closeUpdatePassword,
      isUpdatePasswordOpen,
      openUpdatePassword,
      isSendSupportRequestOpened,
      setIsSendSupportRequestOpened,
    ],
  );

  return (
    <GlobalDataContext.Provider value={contextValue}>
      {children}
    </GlobalDataContext.Provider>
  );
};

export default GlobalDataProvider;
