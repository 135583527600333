import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const InfoRowWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('desktop')]: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  [theme.breakpoints.down('desktop')]: {
    flexDirection: 'column-reverse',
  },
}));
