import React, { FC, useMemo } from 'react';
import { useLocation, useOutlet } from 'react-router-dom';

import Main from './styles/Main';
import MainLayoutWrapper from './styles/MainLayoutWrapper';
import routePaths from '../../../constants/routePaths';
import useGlobalData from '../../../contexts/globalData';
import { MobileMenu } from '../../common/Header/MobileMenu';
import SupportRequest from '../../common/Header/SupportRequest';
import Header from 'components/common/Header';

const MainLayout: FC = () => {
  const outlet = useOutlet();
  const { pathname } = useLocation();
  const { isMobileMenuOpen, isSendSupportRequestOpened } = useGlobalData();
  const showBgImage = useMemo(
    () => pathname === routePaths.parks.root,
    [pathname],
  );
  const noPaddings = useMemo(() => pathname === routePaths.reports, [pathname]);

  return (
    <MainLayoutWrapper showBgImage={showBgImage}>
      <Header showBgImage={showBgImage} />
      <Main noPaddings={noPaddings}>{outlet}</Main>
      {isMobileMenuOpen && <MobileMenu />}
      {isSendSupportRequestOpened && <SupportRequest />}
    </MainLayoutWrapper>
  );
};

export default MainLayout;
