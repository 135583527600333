import { Chip, styled } from '@mui/material';

const MultipleSelectChip = styled(Chip)(({ theme }) => ({
  height: '26px',
  padding: theme.spacing(0, 1.5),
  borderRadius: '20px',
  ...theme.typography.bodyS,
  backgroundColor: 'rgba(50, 57, 68, 0.05)', // not presented in ui kit
  border: 'none',
  boxShadow: `0 0 0 1px inset ${theme.palette.grey[300]}`,

  '& .MuiChip-label': {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: 0,
    color: theme.palette.black[500],
    textTransform: 'none',
  },
}));

export default MultipleSelectChip;
