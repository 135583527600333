import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { IReportContext, IReportValues, ReportContext } from './ReportContext';
import useApi from 'contexts/api';
import { useAsyncResource } from 'utils/hooks/useAsyncResource';

const ReportProvider: FC<PropsWithChildren> = ({ children }) => {
  const { assetControllerApi } = useApi();
  const [reportValues, setReportValues] = useState<IReportValues>();

  const assetPublicIds = useMemo(
    (): string[] =>
      reportValues?.assets
        .map((asset) => asset.publicId)
        .filter((publicAssetId): publicAssetId is string => !!publicAssetId) ||
      [],
    [reportValues?.assets],
  );

  const getReportPanels = useCallback(async () => {
    if (!reportValues) return undefined;

    try {
      return (
        await assetControllerApi.getCustomerReportGrafanaPanels({
          requestBody: assetPublicIds,
        })
      ).data;
    } catch {}
  }, [reportValues, assetPublicIds, assetControllerApi]);

  const { resource: reportPanels, isLoading } = useAsyncResource({
    defaultValue: undefined,
    fetchResource: getReportPanels,
  });

  const contextValue = useMemo(
    (): IReportContext => ({
      reportValues,
      setReportValues,
      reportPanels,
      assetPublicIds,
      loadingPanels: isLoading,
    }),
    [reportValues, setReportValues, reportPanels, assetPublicIds, isLoading],
  );

  return (
    <ReportContext.Provider value={contextValue}>
      {children}
    </ReportContext.Provider>
  );
};

export default ReportProvider;
