import { Box, Grid } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import HeaderWrapper from './styles/HeaderWrapper';
import { LogoLink } from './styles/LogoLink';
import UpdatePassword from './UpdatePassword/UpdatePassword';
import useAuth from '../../../contexts/auth';
import useGlobalData from '../../../contexts/globalData';
import useUser from '../../../contexts/user';
import BurgerMenu from '../../icons/BurgerMenu';
import Logout from '../../icons/Logout';
import Refresh from '../../icons/Refresh';
import CommonButton from '../CommonButton';
import DropdownMenu from '../DropdownMenu';
import { MenuItemType } from '../DropdownMenu/DropdownMenu';
import { NotificationCountersBlock } from '../Notifications/NotificationsCountersBlock';
import Tabs from '../Tabs';
import { TabsComponentVariant } from '../Tabs/types';
import Logo from 'components/icons/Logo';
import routePaths from 'constants/routePaths';
import { generateTabs } from 'constants/tabs';

type Props = {
  showBgImage: boolean;
};

const Header: FC<Props> = ({ showBgImage }) => {
  const { onLogout } = useAuth();
  const { username } = useUser();
  const { setIsSendSupportRequestOpened } = useGlobalData();
  const {
    setIsMobileMenuOpen,
    openUpdatePassword,
    closeUpdatePassword,
    isUpdatePasswordOpen,
  } = useGlobalData();
  const { t } = useTranslation();
  const tabs = useMemo(() => generateTabs(t), [t]);

  const menuItems: MenuItemType[] = useMemo(
    () => [
      {
        label: t`menu.updatePassword`,
        icon: <Refresh />,
        action: openUpdatePassword,
      },
      {
        label: t`logOut`,
        icon: <Logout />,
        action: onLogout,
      },
    ],
    [t, onLogout, openUpdatePassword],
  );

  return (
    <HeaderWrapper showBgImage={showBgImage}>
      <Grid container gap={6.5} width="auto" alignItems="center">
        <LogoLink to={routePaths.parks.root}>
          <Logo />
        </LogoLink>
        <Tabs
          componentVariant={TabsComponentVariant.Menu}
          sx={{ display: { mobile: 'none', desktop: 'flex' } }}
          tabs={tabs}
        />
      </Grid>
      <Grid display="flex" gap={3}>
        <NotificationCountersBlock />
        <Grid
          sx={{
            display: { mobile: 'none', desktop: 'flex' },
            gap: 4,
          }}
        >
          <CommonButton
            variant="contained"
            color="white"
            data-testid="supportRequest"
            size="medium"
            onClick={() => setIsSendSupportRequestOpened(true)}
          >
            {t`buttons.sendSupportRequest`}
          </CommonButton>
          <DropdownMenu title={username} items={menuItems} withExpandIcon />
        </Grid>
        <Box
          sx={{
            display: { mobile: 'flex', desktop: 'none' },
            cursor: 'pointer',
            alignItems: 'center',
          }}
          onClick={() => setIsMobileMenuOpen(true)}
        >
          <BurgerMenu />
        </Box>
      </Grid>
      {isUpdatePasswordOpen && (
        <UpdatePassword closePopup={closeUpdatePassword} />
      )}
    </HeaderWrapper>
  );
};

export default Header;
