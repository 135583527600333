import { styled } from '@mui/material';

const CloseIconWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  '& svg': {
    color: theme.palette.grey[300],
  },
}));

export default CloseIconWrapper;
