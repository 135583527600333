import { SxProps } from '@mui/material';
import { FC } from 'react';

import { Iframe } from './styles';
import { WIDGETS } from '../../constants';
import useWindTurbinePanels from 'contexts/WindTurbinePanels';
import { GrafanaIframeTheme, PeriodFilterValues } from 'types/general';
import { useGrafanaWidgetLink } from 'utils/hooks/useGrafanaWidgetLink';

interface Props {
  panelId: WIDGETS;
  theme?: GrafanaIframeTheme;
  from?: PeriodFilterValues;
  shouldRefresh?: boolean;
  sx?: SxProps;
}

export const GrafanaIframe: FC<Props> = ({
  panelId,
  theme = GrafanaIframeTheme.light,
  from,
  shouldRefresh = true,
  sx,
}) => {
  const { panels } = useWindTurbinePanels();
  const widgetLink = useGrafanaWidgetLink(
    String(panelId),
    panels?.[panelId] || {},
    theme,
    from,
    shouldRefresh,
  );

  return (
    <>
      {!!widgetLink && (
        <Iframe
          src={widgetLink}
          title={String(WIDGETS.WIND_SPEED_CHART)}
          sx={sx}
        />
      )}
    </>
  );
};
