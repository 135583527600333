import { styled } from '@mui/material';

import CommonButton from 'components/common/CommonButton';
import { ICommonButtonProps } from 'components/common/CommonButton/CommonButton';

const ClearButton = styled((props: ICommonButtonProps) => (
  <CommonButton {...props} />
))(({ theme }) => ({
  '&: hover': {
    color: theme.palette.black[900],
  },

  '&.Mui-disabled': {
    color: theme.palette.grey[800],
    opacity: 0.45,
  },
}));

export default ClearButton;
