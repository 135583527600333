import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { defaultValues } from './constants';
import { ValidationLine } from './ValidationLine';
import validationSchema, {
  atLeastOneLowercase,
  atLeastOneNumber,
  atLeastOneSpecial,
  atLeastOneUppercase,
  lengthValidation,
} from './validationSchema';
import useApi from '../../../../contexts/api';
import useResponsePopup from '../../../../contexts/responsePopup';
import { useHandleResponseError } from '../../../../utils/hooks/useHandleResponseError';
import CommonButton from '../../CommonButton';
import CommonDialog from '../../CommonDialog';
import { PasswordField } from '../../form/PasswordField/PasswordField';
import ButtonsWrapper from '../styles/ButtonsWrapper';
import { NotificationPopupVariants } from 'components/common/NotificationPopup/types';
import FormWrapper from 'components/pages/Login/styles/FormWrapper';
import { CustomerUpdatePasswordRequestDto } from 'openapi-api/admin-service';

type Props = {
  closePopup: () => void;
};
const UpdatePassword = ({ closePopup }: Props) => {
  const { t } = useTranslation();
  const { customerControllerApi } = useApi();
  const { openPopup, closePopup: closeResponsePopup } = useResponsePopup();
  const form = useForm<CustomerUpdatePasswordRequestDto>({
    resolver: yupResolver(validationSchema(t)),
    defaultValues,
    mode: 'onTouched',
  });
  const {
    handleSubmit: handleSubmitForm,
    formState: { isValid, isDirty },
    watch,
    trigger,
  } = form;
  const { handleResponse } = useHandleResponseError();

  const newPassword = watch('newPassword');
  const newPasswordRepeat = watch('newPasswordRepeat');

  useEffect(() => {
    if (!!newPasswordRepeat) {
      trigger('newPasswordRepeat');
    }
  }, [newPassword, newPasswordRepeat, trigger]);

  const handleSubmit = handleSubmitForm(
    async (data: CustomerUpdatePasswordRequestDto) => {
      try {
        await customerControllerApi.updatePassword1({
          customerUpdatePasswordRequestDto: data,
        });
        closePopup();
        openPopup({
          variant: NotificationPopupVariants.Success,
          title: t`popup.updatePassword.wasChanged`,
          primaryButton: {
            text: t`autoLogoutModal.continue`,
            onClick: closeResponsePopup,
          },
        });
      } catch (e) {
        handleResponse(e);
      }
    },
  );

  return (
    <CommonDialog onClose={closePopup} open width={540} align="left">
      <Typography
        sx={{ mb: 5 }}
        variant="h2"
      >{t`popup.updatePassword.title`}</Typography>
      <Typography
        sx={{ mb: 4 }}
        variant="bodyM"
      >{t`popup.updatePassword.description`}</Typography>
      <FormProvider {...form}>
        <FormWrapper onSubmit={handleSubmit}>
          <PasswordField
            sx={{ mb: 4 }}
            name="currentPassword"
            label={t`popup.updatePassword.currentPasswords`}
          />
          <PasswordField
            sx={{ mb: 2 }}
            name="newPassword"
            label={t`popup.updatePassword.password`}
          />
          <Grid
            display="grid"
            gridTemplateColumns="1fr 1fr"
            rowGap={1}
            columnGap={1.5}
            marginBottom={3}
            width="100%"
          >
            <ValidationLine
              value={newPassword}
              title={t`popup.updatePassword.passwordLength`}
              regExp={lengthValidation}
            />
            <ValidationLine
              value={newPassword}
              title={t`popup.updatePassword.oneNumber`}
              regExp={atLeastOneNumber}
            />
            <ValidationLine
              value={newPassword}
              title={t`popup.updatePassword.oneLowercase`}
              regExp={atLeastOneLowercase}
            />
            <ValidationLine
              value={newPassword}
              title={t`popup.updatePassword.oneUppercase`}
              regExp={atLeastOneUppercase}
            />
            <ValidationLine
              value={newPassword}
              title={t`popup.updatePassword.oneSpecial`}
              regExp={atLeastOneSpecial}
            />
          </Grid>
          <PasswordField
            name="newPasswordRepeat"
            label={t`popup.updatePassword.passwordRepeat`}
          />
          <ButtonsWrapper
            paddingTop={6.5}
            display="flex"
            gap={2}
            justifyContent="center"
          >
            <CommonButton
              variant="contained"
              data-testid="submit"
              type="submit"
              disabled={!isDirty || !isValid}
              sx={{
                width: { mobile: '226px', tablet: 'auto', desktop: '100%' },
              }}
            >
              {t`submit`}
            </CommonButton>
            <CommonButton
              variant="outlined"
              onClick={closePopup}
              data-testid="close-button"
              sx={{
                width: {
                  mobile: 'fit-content',
                  tablet: 'auto',
                  desktop: '100%',
                },
              }}
            >
              {t`cancel`}
            </CommonButton>
          </ButtonsWrapper>
        </FormWrapper>
      </FormProvider>
    </CommonDialog>
  );
};

export default UpdatePassword;
