import { getReportTimeframePeriod } from 'components/pages/protected/Reports/components/CreateReportForm/helpers';
import { FilterTypes, FilterValues, TranslationFunction } from 'types/general';

export const notificationsFilterValues = (
  t: TranslationFunction,
): FilterValues => ({
  status: {
    type: FilterTypes.radio,
    label: t`pages.notifications.filterStatus`,
    values: [
      {
        label: t`pages.notifications.filterAll`,
        value: 'all',
      },
      {
        label: t`pages.notifications.filterRead`,
        value: 'read',
      },
      {
        label: t`pages.notifications.filterUnread`,
        value: 'unread',
      },
    ],
  },
  dateRange: {
    label: t`pages.notifications.filterTimePeriod`,
    type: FilterTypes.dates,
    values: [],
  },
});

export const fieldsToSend = [
  'search',
  'dateRange',
  'status',
  'type',
  'size',
  'page',
];

export const dateStringToObject = (date?: string) => {
  if (date?.includes(',')) {
    const [from, to] = date.split(',');
    return { from, to };
  }
  const dayjsPeriod = getReportTimeframePeriod(date);
  return {
    from: dayjsPeriod?.from.toISOString(),
    to: dayjsPeriod?.to.toISOString(),
  };
};
