import { styled } from '@mui/material';

export const UviChip = styled('div', {
  shouldForwardProp: (propName) => propName !== 'uvIndex',
})<{ uvIndex?: number }>(({ theme, uvIndex = 0 }) => {
  let backgroundColor = '';

  switch (true) {
    case uvIndex < 3:
      backgroundColor = theme.palette.green[200] as string;
      break;
    case uvIndex < 6:
      backgroundColor = '#F8FB79'; // TODO: add to palette
      break;
    case uvIndex < 8:
      backgroundColor = theme.palette.warning[20] as string;
      break;
    case uvIndex < 11:
      backgroundColor = theme.palette.warning.main;
      break;
    case uvIndex >= 11:
      backgroundColor = '#E4C1FF'; // TODO: add to palette
      break;
    default:
      backgroundColor = theme.palette.grey[200];
  }

  return {
    display: 'flex',
    width: 'fit-content',
    padding: theme.spacing(0.25, 1),
    borderRadius: '15px',
    backgroundColor,
  };
});
