import { FC } from 'react';

const Warning: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="65"
    viewBox="0 0 64 65"
    fill="none"
  >
    <rect width="64" height="64" transform="translate(0 0.5)" fill="white" />
    <path
      d="M29.1786 15.6298C30.4019 13.4055 33.598 13.4056 34.8214 15.6298L51.3755 45.7282C52.5558 47.8742 51.0032 50.5 48.5541 50.5H15.4459C12.9968 50.5 11.4442 47.8742 12.6245 45.7282L29.1786 15.6298Z"
      fill="#FF623F"
    />
    <path
      d="M30.0413 25.1502C30.0186 24.2456 30.7458 23.5 31.6508 23.5H32.3492C33.2542 23.5 33.9814 24.2456 33.9587 25.1502L33.6392 37.9302C33.6174 38.8035 32.9033 39.5 32.0297 39.5H31.9703C31.0968 39.5 30.3826 38.8035 30.3608 37.9302L30.0413 25.1502Z"
      fill="white"
    />
    <path
      d="M29.8279 44.9696C29.6825 43.7433 30.6405 42.665 31.8754 42.665H32.1228C33.3578 42.665 34.3157 43.7433 34.1704 44.9696C34.0473 46.0072 33.1677 46.7888 32.1228 46.7888H31.8754C30.8306 46.7888 29.9509 46.0072 29.8279 44.9696Z"
      fill="white"
    />
  </svg>
);

export default Warning;
