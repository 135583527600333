import { alpha } from '@mui/material';
import { styled } from '@mui/material/styles';

export const NotificationCountersWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  paddingRight: theme.spacing(2.5),
  border: `1px solid ${alpha(theme.palette.white.main, 0.5)}`,
  backgroundColor: alpha(theme.palette.white.main, 0.1),
  borderRadius: '18px',
  cursor: 'pointer',
}));
