import Typography from '@mui/material/Typography';
import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import App from 'App';
import ProtectedRoutes from 'components/common/ProtectedRoutes';
import Login from 'components/pages/Login';
import MainLayout from 'components/pages/protected/MainLayout';
import Notifications from 'components/pages/protected/Notifications';
import { Park } from 'components/pages/protected/Park';
import Parks from 'components/pages/protected/Parks';
import { Reports } from 'components/pages/protected/Reports';
import { ErrorReport } from 'components/pages/protected/WindTurbines/ErrorReport/ErrorReport';
import {
  WindTurbineGeneralInfo,
  WindTurbinePage,
  WindTurbineStatuses,
} from 'components/pages/protected/WindTurbines/WindTurbinePage';
import routePaths from 'constants/routePaths';

export const appRoutes = [
  {
    path: routePaths.root,
    element: <Navigate replace to={routePaths.parks.root} />,
  },
  {
    path: routePaths.parks.root,
    children: [
      {
        path: routePaths.parks.root,
        element: <Parks />,
      },
      {
        path: routePaths.parks.specific(':locationId').root,
        children: [
          {
            path: routePaths.parks.specific(':locationId').root,
            element: <Park />,
          },
          {
            path: routePaths.parks
              .specific(':locationId')
              .windTurbine(':publicId').root,
            element: <WindTurbinePage />,
            children: [
              {
                path: routePaths.parks
                  .specific(':locationId')
                  .windTurbine(':publicId').root,
                element: <Navigate replace to="general" />,
              },
              {
                path: 'general',
                element: <WindTurbineGeneralInfo />,
              },
              {
                path: 'statuses',
                element: <WindTurbineStatuses />,
              },
            ],
          },
          {
            path: routePaths.parks
              .specific(':locationId')
              .windTurbine(':publicId').errorReport,
            element: <ErrorReport />,
          },
        ],
      },
    ],
  },
  {
    path: routePaths.reports,
    element: <Reports />,
  },
  {
    path: routePaths.notifications(':type'),
    element: <Notifications />,
  },
];

const routes: RouteObject[] = [
  {
    path: routePaths.root,
    element: <App />,
    children: [
      {
        path: routePaths.root,
        element: <ProtectedRoutes />,
        children: [
          {
            path: routePaths.root,
            element: <MainLayout />,
            children: appRoutes,
          },
        ],
      },
      {
        path: routePaths.login,
        element: <Login />,
      },
      {
        path: '*',
        element: <Typography variant="h1">{'404 Not Found'}</Typography>,
      },
    ],
  },
];

export default routes;
