import { Box, Chip, ChipProps, useTheme } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  NotificationDto,
  NotificationDtoTypeEnum,
} from 'openapi-api/admin-service';

interface Props {
  notification: NotificationDto;
}

export const NotificationChip: FC<Props> = ({ notification }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const props: ChipProps & { iconColor?: string } = useMemo(() => {
    switch (notification.type) {
      case NotificationDtoTypeEnum.ERROR:
        return {
          color: 'error',
          label: t('error'),
          iconColor: theme.palette.error.main,
        };
      case NotificationDtoTypeEnum.WARNING:
        return {
          color: 'warning',
          label: t('pages.notifications.warning'),
          iconColor: theme.palette.warning.main,
        };
      case NotificationDtoTypeEnum.REMINDER:
        return {
          color: 'success',
          label: t('pages.notifications.reminder'),
          iconColor: theme.palette.green[600],
        };
      default:
        return {};
    }
  }, [notification, t, theme]);

  return (
    <Chip
      sx={{
        '& .MuiChip-label': {
          textTransform: 'none',
          ...theme.typography.bodyS,
        },
      }}
      icon={
        <Box
          width="12px"
          height="12px"
          sx={() => ({
            borderRadius: '50%',
            backgroundColor: props.iconColor,
          })}
        />
      }
      size="small"
      {...props}
    />
  );
};
