import { Select, styled } from '@mui/material';

const SelectStyled = styled(Select)(() => ({
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&.MuiInputBase-root': {
    marginBottom: 0,
  },
  '& .MuiSelect-icon': {
    top: '16px',
    right: '16px',
  },
  '& .MuiGrid-root': {
    flexWrap: 'wrap',
  },
}));

export default SelectStyled;
