import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CommonButton from '../../../../../common/CommonButton';
import { NotificationPopupVariants } from '../../../../../common/NotificationPopup/types';
import useApi from 'contexts/api';
import useReport from 'contexts/report';
import useResponsePopup from 'contexts/responsePopup';
import useUser from 'contexts/user';
import { ReportRequestDtoReportFormatEnum } from 'openapi-api/report-service';
import { useHandleResponseError } from 'utils/hooks/useHandleResponseError';

interface Props {
  reportFormat: ReportRequestDtoReportFormatEnum;
}

export const GenerateReportButton: FC<Props> = ({ reportFormat }) => {
  const { customerId, customerName } = useUser();
  const { reportValues, assetPublicIds } = useReport();
  const [requested, setRequested] = useState(false);
  const { t } = useTranslation();
  const { reportRequestControllerApi } = useApi();
  const { openPopup, closePopup: closeResponsePopup } = useResponsePopup();

  const { handleResponse } = useHandleResponseError();

  const handleClick = useCallback(async () => {
    if (!reportValues || customerId === undefined || !customerName) return;

    try {
      await reportRequestControllerApi.createReportRequest({
        reportRequestDto: {
          reportFormat,
          customerId,
          customerName,
          assetPublicIds,
          startDatetime: reportValues.period.from,
          endDatetime: reportValues.period.to,
        },
      });

      openPopup({
        variant: NotificationPopupVariants.Success,
        title: t('pages.reports.generateReportSuccessTitle'),
        subtitle: t('pages.reports.generateReportSuccessSubtitle'),
        primaryButton: {
          text: t('continueSession'),
          onClick: closeResponsePopup,
        },
      });

      setRequested(true);
    } catch (e) {
      handleResponse(e);
    }
  }, [
    reportFormat,
    customerId,
    customerName,
    reportValues,
    assetPublicIds,
    reportRequestControllerApi,
    openPopup,
    t,
    closeResponsePopup,
    handleResponse,
  ]);

  useEffect(() => {
    setRequested(false);
  }, [reportValues]);

  return (
    <CommonButton disabled={requested} onClick={handleClick}>
      {requested
        ? t('pages.reports.generateCsvRequested')
        : t('pages.reports.generateCsv')}
    </CommonButton>
  );
};
