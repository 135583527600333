export enum ReportTimeframes {
  today = 'today',
  yesterday = 'yesterday',
  lastWeek = 'lastWeek',
  lastMonth = 'lastMonth',
}

export type CreateReportFormValues = {
  locationNames: string[];
  assetIds: string[];
  reportPeriod?: string;
  reportFromDate?: string;
  reportToDate?: string;
};
