import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const ForgotWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  margin: theme.spacing(2.5, 0, 6),
  [theme.breakpoints.only('tablet')]: {
    margin: theme.spacing(3, 0, 5),
  },
}));

export default ForgotWrapper;
