import { AssetDetailsDto } from 'openapi-api/admin-service';

export enum ReportWidgets {
  AggregatedTotal = '12',
  SpecificTotalActiveEnergy = '50',
  SpecificPerfomanceDiagram = '41',
  Errors = '39',
}

export enum ReportWidgetsMobile {
  AggregatedTotal = '48',
  SpecificTotalActiveEnergy = '51',
  SpecificPerfomanceDiagram = '47',
  Errors = '49',
}

export interface IParkToAssets {
  [key: string]: AssetDetailsDto[];
}
