import { Radio, RadioProps } from '@mui/material';
import { FC } from 'react';

import RadioChecked from 'components/icons/RadioChecked';
import RadioDefault from 'components/icons/RadioDefault';

const RadioCustom: FC<RadioProps> = (props) => {
  return (
    <Radio {...props} icon={<RadioDefault />} checkedIcon={<RadioChecked />} />
  );
};

export default RadioCustom;
