import { Typography } from '@mui/material';
import { FC } from 'react';
import { Trans } from 'react-i18next';

import { WTLink } from './WTLink';
import { getWindTurbinePageLink } from 'components/pages/protected/WindTurbines/WindTurbinePage/helpers';
import { AssetTagsCollectionErrorDto } from 'openapi-api/admin-service';
import { getStringFromISODate } from 'utils/functions/getStringFromISODate';

interface Props {
  details: AssetTagsCollectionErrorDto;
  extended: boolean;
  locationId?: number | string;
}

const TemplateTagsCollectingError: FC<Props> = ({
  details,
  extended,
  locationId,
}) => {
  const { publicId, eventDateTime, assetNameForUser } = details;

  if (!publicId) {
    return null;
  }

  return (
    <>
      <Typography variant={extended ? 'bodyM' : 'bodyS'}>
        <Trans
          i18nKey="notifications.collectingTagsErrors"
          values={{ eventDateTime: getStringFromISODate(eventDateTime) }}
          components={[
            <WTLink
              key={`collectingTagsErrors-link-${publicId}`}
              link={
                getWindTurbinePageLink({
                  assetLocationId: locationId,
                  assetPublicId: publicId,
                }).statuses
              }
              publicId={publicId}
              linkText={assetNameForUser}
              newTab={extended}
            />,
          ]}
        />
      </Typography>
    </>
  );
};

export default TemplateTagsCollectingError;
