import { styled } from '@mui/material/styles';

const MobileMenuHeader = styled('div')(({ theme }) => ({
  height: '56px',
  padding: theme.spacing(0, 2.5),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export default MobileMenuHeader;
