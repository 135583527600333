import { styled } from '@mui/material';

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(4),

  [theme.breakpoints.down('desktop')]: {
    flexDirection: 'column',
    gap: theme.spacing(5),
  },
}));

export default Wrapper;
