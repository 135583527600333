import { Box, Typography } from '@mui/material';
import { FC, useMemo } from 'react';

import { ButtonsWrapper, CloseButton, Dialog } from './styles';
import { NotificationPopupProps, NotificationPopupVariants } from './types';
import CommonButton from '../CommonButton';
import Close from 'components/icons/Close';
import SuccessIcon from 'components/icons/SuccessIcon';
import Warning from 'components/icons/Warning';

export const NotificationPopup: FC<NotificationPopupProps> = ({
  variant = NotificationPopupVariants.Warning,
  title,
  isOpen,
  primaryButton,
  secondaryButton,
  children,
  onClose,
  subtitle,
}) => {
  const icon = useMemo(() => {
    if (variant === NotificationPopupVariants.Success) {
      return <SuccessIcon />;
    }

    if (variant === NotificationPopupVariants.Warning) {
      return <Warning />;
    }
  }, [variant]);

  return (
    <Dialog open={!!isOpen} onClose={onClose} variant={variant}>
      {icon}
      <Typography variant="h1" sx={{ mt: 1.5 }}>
        {title}
      </Typography>
      {subtitle && (
        <Typography variant="bodyM" sx={{ mt: 2.5 }} color="grey.800">
          {subtitle}
        </Typography>
      )}
      {children && <Box sx={{ mt: 4 }}>{children}</Box>}
      <ButtonsWrapper>
        {primaryButton && (
          <CommonButton
            onClick={primaryButton.onClick}
            sx={{ width: { mobile: '226px', tablet: 'auto' } }}
            color="black"
          >
            {primaryButton.text}
          </CommonButton>
        )}
        {secondaryButton && (
          <CommonButton
            variant="outlined"
            onClick={secondaryButton.onClick}
            sx={{ width: 'fit-content' }}
          >
            {secondaryButton.text}
          </CommonButton>
        )}
      </ButtonsWrapper>
      <CloseButton onClick={onClose}>
        <Close />
      </CloseButton>
    </Dialog>
  );
};
