import {
  LocationWeatherShortDto,
  LocationWithWindTurbineMetricsDto,
} from 'openapi-api/admin-service';

export type StatisticType = 'active' | 'inactive' | 'error';

export enum ParkAssetsStatuses {
  active = 'ACTIVE',
  inactive = 'INACTIVE',
  error = 'ERROR',
}

export enum ParksSortingValues {
  addedDatetime = 'ADD_TO_LOCATION_DATETIME',
  activeEnergy = 'TOTAL_ACTIVE_ENERGY',
}

export enum ParksSortingOrder {
  desc = 'DESC',
  asc = 'ASC',
}

export interface IParkItem {
  data: LocationWithWindTurbineMetricsDto;
  weather?: LocationWeatherShortDto;
}
