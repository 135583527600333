import { FC } from 'react';

const SuccessIcon: FC = () => (
  <svg
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="36" cy="36" r="36" fill="#F5F7FB" />
    <circle opacity="0.6" cx="36" cy="36" r="28" fill="#21252C" />
    <g clipPath="url(#clip0_3115_3825)">
      <circle cx="36" cy="36" r="20" fill="#0F1012" />
      <path
        d="M25.167 35.1667L33.5003 43.5L46.0003 29.3334"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3115_3825">
        <rect
          width="40"
          height="40"
          fill="white"
          transform="translate(16 16)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SuccessIcon;
