import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  errorsHeight,
  noActiveStatusesOrErrorsHeight,
  statusesHeight,
} from './constants';
import {
  ContentBlock,
  IframeWrapper,
  ScrollableWrapper,
  Wrapper,
} from './styles';
import { WIDGETS } from '../../constants';
import { GrafanaIframe } from '../GrafanaIframe';
import useWindTurbine from 'contexts/WindTurbine';
import { WindTurbinePanelsProvider } from 'contexts/WindTurbinePanels';
import {
  WindTurbineDtoErrorTypeEnum,
  WindTurbineDtoStatusEnum,
} from 'openapi-api/admin-service';

const WindTurbineStatusesContent: FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isNotDesktop = useMediaQuery(theme.breakpoints.down('desktop'));
  const { windTurbine } = useWindTurbine();

  const noActiveStatusesOrErrors =
    windTurbine?.status === WindTurbineDtoStatusEnum.ERROR &&
    windTurbine?.errorType === WindTurbineDtoErrorTypeEnum.CONNECTIVITY;

  const [statusesContainerHeight, errorsContainerHeight] =
    noActiveStatusesOrErrors
      ? [noActiveStatusesOrErrorsHeight, noActiveStatusesOrErrorsHeight]
      : [statusesHeight, errorsHeight];

  return (
    <WindTurbinePanelsProvider dashboardType="ERRORS_STATUSES">
      <Wrapper>
        <ContentBlock>
          <Typography variant="h4">
            {t`pages.turbine.tabs.statuses.statuses`}
          </Typography>
          <IframeWrapper height={statusesContainerHeight}>
            <GrafanaIframe
              panelId={
                isNotDesktop ? WIDGETS.STATUSES_MOBILE : WIDGETS.STATUSES
              }
            />
          </IframeWrapper>
        </ContentBlock>
        <ContentBlock>
          <Typography variant="h4">
            {t`pages.turbine.tabs.statuses.errors`}
          </Typography>
          <ScrollableWrapper height={statusesContainerHeight}>
            <IframeWrapper height={errorsContainerHeight}>
              <GrafanaIframe
                panelId={isNotDesktop ? WIDGETS.ERRORS_MOBILE : WIDGETS.ERRORS}
              />
            </IframeWrapper>
          </ScrollableWrapper>
        </ContentBlock>
      </Wrapper>
    </WindTurbinePanelsProvider>
  );
};

export default WindTurbineStatusesContent;
