import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

const ButtonsWrapper = styled(Grid)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('tablet')]: {
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(3),
  },
}));

export default ButtonsWrapper;
