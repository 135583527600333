import { FC } from 'react';

const Refresh: FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 11.9998C21 7.02919 16.9706 2.99976 12 2.99976C9.17273 2.99976 6.64996 4.30342 5 6.34242"
      stroke="#14181F"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.5 2.99976L4.5 6.99976L8.5 6.99976"
      stroke="#14181F"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 11.9998C3 16.9703 7.02944 20.9998 12 20.9998C14.8273 20.9998 17.35 19.6961 19 17.6571"
      stroke="#14181F"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.5 20.9998L19.5 16.9998L15.5 16.9998"
      stroke="#14181F"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Refresh;
