import { FC } from 'react';

import { CheckboxFilter } from './CheckboxFilter';
import { DateRangeFilter } from './DateRangeFilter';
import { RadioFilter } from './RadioFilter';
import { FilterProps } from '../types';
import { FilterTypes } from 'types/general';

export const FilterWrapper: FC<FilterProps> = ({ type, ...rest }) => {
  switch (type) {
    case FilterTypes.radio:
      return <RadioFilter {...rest} />;
    case FilterTypes.dates:
      return <DateRangeFilter {...rest} />;
    default:
      return <CheckboxFilter {...rest} />;
  }
};
