import { SxProps, Typography } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { AppliedFilterItem, AppliedFiltersWrapper, CloseIcon } from './styles';
import Close from 'components/icons/Close';
import { FilterItem, FilterModel, FilterValues } from 'types/general';

interface Props {
  checkedFilterValues: Record<string, FilterItem>;
  filterValues: FilterValues;
  onFilterModelChange: (filterModel: FilterModel) => void;
  className?: string;
  sx?: SxProps;
}

export const AppliedFilters: FC<Props> = ({
  checkedFilterValues,
  filterValues,
  onFilterModelChange,
  className,
  sx,
}) => {
  const [searchParams] = useSearchParams();

  const onAppliedFilterItemClick = useCallback(
    (field: string, value: string) => {
      const currentFieldValue = searchParams.get(field)?.split(',') || [];
      if (currentFieldValue.length) {
        onFilterModelChange({
          field,
          value: currentFieldValue
            .filter((fieldValue) => fieldValue !== value)
            .join(','),
        });
        return;
      }

      onFilterModelChange({ field, value: undefined });
    },
    [searchParams, onFilterModelChange],
  );

  const appliedFilterValues = useMemo(() => {
    return Object.entries(filterValues).reduce(
      (
        appliedValues: { [key: string]: FilterItem[] },
        [field, fieldContent],
      ) => {
        const valuesFromQuery = checkedFilterValues[field].value?.split(',');
        if (valuesFromQuery?.length) {
          const appliedFilterItems = fieldContent.values.filter(
            (item) => item.value && valuesFromQuery.includes(item.value),
          );
          return {
            ...appliedValues,
            [field]: appliedFilterItems,
          };
        }

        return appliedValues;
      },
      {},
    );
  }, [checkedFilterValues, filterValues]);

  return (
    <AppliedFiltersWrapper className={className} sx={sx}>
      {Object.entries(appliedFilterValues).map(([field, fieldItems]) =>
        fieldItems.map((fieldItem) => (
          <AppliedFilterItem key={`${field}-${fieldItem.value}`}>
            <Typography variant="bodyS">{fieldItem.label}</Typography>
            <CloseIcon
              onClick={() =>
                onAppliedFilterItemClick(field, fieldItem.value as string)
              }
            >
              <Close />
            </CloseIcon>
          </AppliedFilterItem>
        )),
      )}
    </AppliedFiltersWrapper>
  );
};
