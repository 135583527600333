import { Box, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { MobileSubMenu } from './MobileSubMenu';
import { LogoLink } from './styles/LogoLink';
import MobileMenuBody from './styles/MobileMenuBody';
import MobileMenuHeader from './styles/MobileMenuHeader';
import MobileMenuWrapper from './styles/MobileMenuWrapper';
import TabWrapper from './styles/TabWrapper';
import { generateTabs } from '../../../constants/tabs';
import Refresh from '../../icons/Refresh';
import CommonButton from '../CommonButton';
import { Link } from '../Link';
import Close from 'components/icons/Close';
import Logo from 'components/icons/Logo';
import routePaths from 'constants/routePaths';
import useAuth from 'contexts/auth';
import useGlobalData from 'contexts/globalData';
import useUser from 'contexts/user';

export const MobileMenu = () => {
  const {
    setIsMobileMenuOpen,
    openUpdatePassword,
    setIsSendSupportRequestOpened,
  } = useGlobalData();
  const { onLogout } = useAuth();
  const { username } = useUser();
  const { t } = useTranslation();

  const tabs = useMemo(() => generateTabs(t), [t]);

  return (
    <MobileMenuWrapper>
      <MobileMenuHeader>
        <LogoLink
          to={routePaths.parks.root}
          onClick={() => setIsMobileMenuOpen(false)}
        >
          <Logo />
        </LogoLink>
        <Box
          data-testid="close-mobile-menu"
          onClick={() => setIsMobileMenuOpen(false)}
        >
          <Close />
        </Box>
      </MobileMenuHeader>
      <MobileMenuBody>
        <Grid>
          {tabs.map((tab) =>
            !!tab.submenu ? (
              <MobileSubMenu
                key={tab.value}
                tab={tab}
                itemClick={() => !tab.disabled && setIsMobileMenuOpen(false)}
              />
            ) : (
              <Link
                key={tab.value}
                to={tab.value}
                component="a"
                sx={{ textDecoration: 'none' }}
              >
                <TabWrapper
                  onClick={() => !tab.disabled && setIsMobileMenuOpen(false)}
                  disabled={tab.disabled}
                >
                  <Typography variant="h4">{tab.label}</Typography>
                </TabWrapper>
              </Link>
            ),
          )}
          <CommonButton
            variant="contained"
            color="white"
            data-testid="supportRequest"
            onClick={() => {
              setIsMobileMenuOpen(false);
              setIsSendSupportRequestOpened(true);
            }}
            sx={{ width: '100%', mt: 4 }}
          >
            {t`buttons.sendSupportRequest`}
          </CommonButton>
        </Grid>
        <Grid gap={3} container flexDirection="column" alignItems="center">
          <Grid container flexDirection="column" alignItems="center" gap={0.5}>
            <Typography variant="bodyMStrong" color="black.600">
              {username}
            </Typography>
          </Grid>
          <Grid
            gap={2.5}
            marginTop={1}
            display="flex"
            onClick={() => {
              openUpdatePassword();
              setIsMobileMenuOpen(false);
            }}
          >
            <Refresh />
            <Typography variant="bodyL">{t`menu.updatePassword`}</Typography>
          </Grid>
          <CommonButton
            type="submit"
            variant="contained"
            color="black"
            onClick={() => {
              setIsMobileMenuOpen(false);
              onLogout();
            }}
            sx={{ width: '100%', maxWidth: '335px' }}
          >
            {t`logOut`}
          </CommonButton>
        </Grid>
      </MobileMenuBody>
    </MobileMenuWrapper>
  );
};
