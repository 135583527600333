import { styled } from '@mui/material';

const FieldsWrapperGeneral = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: theme.spacing(3),

  [theme.breakpoints.down('desktop')]: {
    gridTemplateColumns: '1fr',
  },
}));

export default FieldsWrapperGeneral;
