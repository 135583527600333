import { styled } from '@mui/material';

const ContentBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  width: '100%',

  [theme.breakpoints.down('desktop')]: {
    gap: theme.spacing(2.5),
  },
}));

export default ContentBlock;
