import { Checkbox, FormHelperText, InputLabel } from '@mui/material';
import { FC, useCallback } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  CheckMarkIcon,
  CloseIconWrapper,
  FormControlStyled,
  MenuItemStyled,
  MultipleSelectChip,
  MultipleSelectValue,
  NoData,
  SelectStyled,
} from './styles';
import { CustomSelectProps } from './types';
import Checked from 'components/icons/Checked';
import ChevronDownL from 'components/icons/ChevronDownL';
import Close from 'components/icons/Close';
import { SelectItem } from 'types/general';

const Select: FC<CustomSelectProps> = ({
  name,
  label,
  items,
  helperText,
  onItemClick,
  fullWidth,
  multiple,
  disabled,
  sx,
  ...otherProps
}) => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext();
  const {
    field,
    fieldState: { error },
  } = useController({ control, name });
  const helperTextValue = error?.message || helperText;

  const isItemSelected = useCallback(
    (value?: string) =>
      Array.isArray(field.value)
        ? !!field.value.find((v) => v === value)
        : field.value === value,
    [field.value],
  );

  const renderValue = useCallback(
    (value: unknown) => {
      if (Array.isArray(value)) {
        return (
          <MultipleSelectValue>
            {value.map((v) => (
              <MultipleSelectChip
                label={
                  <>
                    {items.find((item) => item.value === v)?.label}
                    <CloseIconWrapper
                      onMouseDown={(e) => {
                        e.stopPropagation();
                        setValue(
                          name,
                          field.value.filter((item: SelectItem) => item !== v),
                        );
                      }}
                    >
                      <Close />
                    </CloseIconWrapper>
                  </>
                }
                key={v}
                variant="outlined"
              />
            ))}
          </MultipleSelectValue>
        );
      }

      return items.find((item) => item.value === value)?.label;
    },
    [items, field.value, name, setValue],
  );

  return (
    <FormControlStyled fullWidth={fullWidth} disabled={disabled} sx={sx}>
      <InputLabel htmlFor={field.name}>{label}</InputLabel>
      <SelectStyled
        {...field}
        multiple={multiple}
        IconComponent={ChevronDownL}
        renderValue={renderValue}
        MenuProps={{
          variant: 'menu',
          autoFocus: false,
          sx: () => ({
            '& .MuiMenu-paper': {
              marginTop: '12px',
              borderRadius: '12px',
              boxShadow: `0px 5px 10px 0px rgba(30, 34, 40, 0.10), 0px 0px 1px 0px rgba(30, 34, 40, 0.08)`,
              '& .MuiMenu-list': {
                padding: 0,
              },
            },
          }),
        }}
        {...otherProps}
      >
        {!items.length && (
          <NoData variant="bodyM">{t`components.select.noDataPlaceholder`}</NoData>
        )}
        {items.map((item) => (
          <MenuItemStyled
            key={item.value}
            value={item.value}
            selected={isItemSelected(item.value)}
            onClick={onItemClick}
          >
            {multiple ? (
              <>
                <Checkbox
                  checked={isItemSelected(item.value)}
                  size="medium"
                  disableRipple
                  checkedIcon={<Checked />}
                />
                {item.label}
              </>
            ) : (
              <>
                {item.label}
                {isItemSelected(item.value) ? <CheckMarkIcon /> : null}
              </>
            )}
          </MenuItemStyled>
        ))}
      </SelectStyled>
      {helperTextValue ? (
        <FormHelperText error={!!error}>{helperTextValue}</FormHelperText>
      ) : null}
    </FormControlStyled>
  );
};

export default Select;
