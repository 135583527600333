import { MenuItem, styled } from '@mui/material';

const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  padding: theme.spacing(1.5, 2.5),
  ...theme.typography.bodyM,
  '&.Mui-selected:hover, &:hover': {
    backgroundColor: theme.palette.grey[50],
  },
  '&.Mui-selected': {
    backgroundColor: 'unset',
  },

  '& .MuiCheckbox-root': {
    marginRight: theme.spacing(2.5),
    padding: 0,
  },
}));

export default MenuItemStyled;
