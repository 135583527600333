import { styled, Tabs } from '@mui/material';

import { TabsComponentVariant } from '../types';

const TabsStyled = styled(Tabs, {
  shouldForwardProp: (prop) => prop !== 'componentVariant',
})<{ componentVariant: TabsComponentVariant }>(
  ({ theme, componentVariant }) => ({
    minHeight: 'auto',

    ...(componentVariant === TabsComponentVariant.Tabs && {
      '& .MuiTabs-flexContainer': {
        gap: theme.spacing(5),
        [theme.breakpoints.down('desktop')]: {
          gap: theme.spacing(2),
        },
      },

      '& .MuiTabs-indicator': {
        height: '3px',
        borderRadius: '100px',
        backgroundColor: theme.palette.green[600],
      },

      '& .MuiTab-root': {
        minHeight: 'auto',
        maxWidth: 'auto',
        minWidth: 'auto',
        padding: theme.spacing(0, 0, 1.25),
        ...theme.typography.bodyMStrong,
        textTransform: 'none',
        lineHeight: '150%',
        color: theme.palette.black[500],

        '&.Mui-disabled': {
          color: theme.palette.grey[600],
        },
        '&.Mui-selected': {
          color: theme.palette.black[500],
        },
      },
    }),

    ...(componentVariant === TabsComponentVariant.Menu && {
      '& .MuiTabs-flexContainer': {
        gap: theme.spacing(4),
      },

      '& .MuiTabs-indicator': {
        height: '2px',
        backgroundColor: theme.palette.white.main,
      },

      '& .MuiTab-root': {
        minHeight: 'auto',
        maxWidth: 'auto',
        minWidth: 'auto',
        padding: theme.spacing(0.5, 0, 1),
        ...theme.typography.subheading,
        lineHeight: '13px',
        color: theme.palette.black[500],

        '&.Mui-disabled': {
          color: theme.palette.grey[600],
        },

        '&.Mui-selected': {
          color: theme.palette.black[500],
        },
      },
    }),
  }),
);

export default TabsStyled;
