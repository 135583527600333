import { useMemo } from 'react';

import useAuth from 'contexts/auth';
import { GrafanaPanelParametersDto } from 'openapi-api/admin-service';
import { GrafanaIframeTheme, PeriodFilterValues } from 'types/general';

export const useGrafanaWidgetLink = (
  panelId: string,
  data: GrafanaPanelParametersDto,
  theme: GrafanaIframeTheme,
  fromDate?: PeriodFilterValues,
  shouldRefresh?: boolean,
): string | undefined => {
  const { accessData } = useAuth();
  const { host, dashboardUid, dashboardPath, orgId, panelVariables } = data;

  const refreshValue = useMemo(
    () => (shouldRefresh ? '&refresh=5s' : ''),
    [shouldRefresh],
  );
  const fromDateValue = useMemo(
    () => (typeof fromDate !== 'undefined' ? `&from=${fromDate}` : ''),
    [fromDate],
  );
  const panelVariablesValue = useMemo(
    () =>
      panelVariables && Object.values(panelVariables).length
        ? `&${Object.entries(panelVariables)
            .map(([key, value]) => `var-${key}=${value}`)
            .join('&')}`
        : '',
    [panelVariables],
  );

  const link = useMemo(
    () =>
      host
        ? `https://${host}/d-solo/${dashboardUid}/${dashboardPath}?orgId=${orgId}&panelId=${panelId}${fromDateValue}${refreshValue}${panelVariablesValue}&theme=${theme}&auth_token=${accessData?.accessToken}`
        : undefined,
    [
      host,
      dashboardUid,
      dashboardPath,
      orgId,
      panelId,
      accessData?.accessToken,
      fromDateValue,
      refreshValue,
      theme,
      panelVariablesValue,
    ],
  );

  return link;
};
