export const error = 'rgba(255, 98, 63, 1)';
export const warning20 = 'rgba(255, 67, 7, 0.20)';
export const secondaryWarning = '#F9C241';
export const secondaryWarning200 = 'rgba(253, 227, 225, 1)';
export const grey20 = 'rgba(50, 57, 68, 0.20)';
export const grey50 = 'rgba(245, 247, 251, 1)';
export const grey100 = 'rgb(226, 229, 236)';
export const grey200 = 'rgb(212, 215, 220)';
export const grey300 = 'rgb(186, 190, 197)';
export const grey400 = 'rgb(167, 171, 178)';
export const grey500 = 'rgb(137, 142, 150)';
export const grey600 = 'rgb(118, 123, 132)';
export const grey700 = 'rgb(81, 87, 97)';
export const grey800 = 'rgb(64, 71, 83)';
export const grey900 = 'rgb(50, 57, 68)';

export const green50 = 'rgb(248, 254, 240)';
export const green100 = 'rgb(232, 251, 208)';
export const green200 = 'rgb(221, 249, 186)';
export const green300 = 'rgb(205, 246, 154)';
export const green400 = 'rgb(196, 245, 134)';
export const green500 = 'rgb(181, 242, 104)';
export const green600 = 'rgba(164, 226, 86, 1)';
export const green700 = 'rgb(144, 210, 59)';
export const green800 = 'rgb(100, 133, 57)';
export const green900 = 'rgb(76, 102, 44)';

export const whiteMain = '#FFFFFF';

export const black50 = 'rgb(233, 233, 234)';
export const black100 = 'rgb(186, 187, 190)';
export const black200 = 'rgb(153, 155, 158)';
export const black300 = 'rgb(106, 109, 114)';
export const black400 = 'rgb(77, 81, 86)';
export const black500 = 'rgb(33, 37, 44)';
export const black600 = 'rgb(30, 34, 40)';
export const black700 = 'rgb(23, 26, 31)';
export const black800 = 'rgb(18, 20, 24)';
export const black900 = 'rgb(15, 16, 18)';

export const yellow200 = 'rgba(255, 246, 200, 1)';
export const neutralLight = 'rgba(142, 142, 171,1)';
