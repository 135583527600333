import { styled } from '@mui/material';

import InfoCircle from 'components/icons/InfoCircle';

const InfoIcon = styled(InfoCircle)(() => ({
  flexShrink: '0',
  height: '24px',
  width: '24px',
}));

export default InfoIcon;
