import { FC } from 'react';

interface Props {
  className?: string;
}

const ChevronDownS: FC<Props> = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    stroke="#0F1012"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M15 11L12 14L9 11"
      stroke="currentStroke"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ChevronDownS;
