import { Typography, styled } from '@mui/material';

const NoData = styled(Typography)(({ theme }) => ({
  display: 'block',
  padding: theme.spacing(4, 2.5),
  textAlign: 'center',
  color: theme.palette.grey[500],
}));

export default NoData;
