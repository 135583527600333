import { Tabs, styled } from '@mui/material';

export const StyledTabList = styled(Tabs)<{}>(({ theme }) => ({
  flexShrink: 0,
  minHeight: 'unset',
  '& .MuiTabs-flexContainer': {
    padding: theme.spacing(1.5, 2.5, 0),
    gap: theme.spacing(2.5),
  },
  '& .MuiTabs-indicator': {
    height: '3px',
  },
}));
